import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import { SolfyTextField, FormTitle, ContainedButton, SmallTitle } from './customized_components';
import { ReactComponent as Background } from '/static/icons/background.svg';
import { ReactComponent as SolfyLogo } from '/static/icons/solfyLogo.svg';
import { Typography, Paper, IconButton, InputAdornment } from '@mui/material';
import { FormControlLabel, Radio, RadioGroup, Divider } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import visibility icons
import './styles/colors.scss';

function RegisterForm(props) {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        password2: '',
        firstname: '',
        lastname: '',
        userType: 'independent',
        groupid: '',
        school: '',
        location: '',
    });

    const searchParams = new URLSearchParams(location.search);
    const saml_auth = searchParams.get("auth");
    const designatedGroup = searchParams.get("groupid"); 
    const saml_first_name = searchParams.get("first_name"); 
    const saml_last_name = searchParams.get("last_name"); 

    const [errorMessage, setErrorMessage] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showPassword2, setShowPassword2] = useState(false); // State to toggle password2 visibility

    useEffect(() => {
        if (saml_auth === 'true')
            setFormData({ ...formData, ['firstname']: saml_first_name, ['lastname']: saml_last_name });
    }, [saml_auth, saml_first_name, saml_last_name]);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [userValidCode, setUserValidCode] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [validPassword2, setValidPassword2] = useState(true);
    const [validGroup, setValidGroup] = useState(null);
    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const password2InputRef = useRef(null);

    const handleUsernameBlur = (e) => {
        if (e.target.value === '') {
            e.target.blur(); // Release focus
            setUserValidCode('');
        } else {
            axiosInstance.post('/api/username_validate/', formData)
                .then((response) => {
                    if (response.status === 200) {
                        setUserValidCode(response.data.message);
                        const isValid = response.data.message === 'username validated';
                        if (isValid)
                            e.target.blur();
                        else
                            e.target.focus();
                    }
                })
                .catch((error) => {
                    console.error('Error validating username:', error);
                });
        }
    };

    const handlePasswordBlur = (e) => {
        if (e.target.value === '') {
            e.target.blur(); // Release focus
            setValidPassword(true);
        } else {
            const newPassword = e.target.value;
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/;
            const isValid = passwordRegex.test(newPassword);
            setValidPassword(isValid);
            if (isValid)
                e.target.blur();
            else
                e.target.focus();
        }
    };

    const handleSamePasswordBlur = (e) => {
        if (e.target.value === '') {
            e.target.blur(); // Release focus
            setValidPassword2(true);
            password2InputRef.current.blur();
        } else {
            const isValid = e.target.value === formData.password;
            setValidPassword2(isValid);
            if (isValid)
                e.target.blur();
            else
                e.target.focus();
        }
    };

    const setValidGroupCallback = (val) => {
        setValidGroup(val);
    };

    const handleSubmit = (e) => {
        const isValidForm = ((saml_auth || (validPassword && validPassword2 && userValidCode === 'username validated')) && isCheckboxChecked);
        const isInValidGroup = formData.userType == 'pupil' && !validGroup && !designatedGroup;
        e.preventDefault();
        if (isValidForm && !isInValidGroup) {
            axiosInstance.post('/api/register/', formData)
                .then((response) => {
                    if (response.status === 200) {
                        props.setAuthenticatedCallback(true, true);
                        //navigate('/home/');
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401)
                        if (error.response.data.message.includes('user'))
                            setUserValidCode(error.response.data.message);
                        else if (error.response.data.message.includes('group'))
                            setErrorMessage(Tabstext.group_invalid_message[props.lang]);
                        else
                            console.error(error);
                });
        }
    };

    const handleSamlAuthentication = (e) => {
        e.preventDefault();
        axiosInstance.post('/api/saml_register/', { groupid: designatedGroup })
            .then((response) => {
                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403)
                    setErrorMessage('no redirect_url');
                else
                    console.error(error);
            });
    }

    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100%',
                width: '100%',
                position: 'relative',
                overflowY: 'auto',
            }}
        >
            <Background style={{ width: '100%', height: '100%', position: 'absolute', zIndex: -1 }} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <SolfyLogo style={{ position: 'absolute', top: '-50px' }} />
                <Paper className='longPaper' dir={lang_dir} elevation={0} >
                    <FormTitle gutterBottom>
                        {Tabstext.RegisterTab[props.lang]}
                    </FormTitle>
                    {((props.lang === 'he' && saml_auth !== 'true') || saml_auth === 'false') &&
                        <>
                        <ContainedButton onClick={handleSamlAuthentication} style={{ margin: '8px 0' }}>
                            {Tabstext.IdpTab[props.lang]}
                        </ContainedButton>
                        {saml_auth=='false' &&
                            <Typography color='error' style={{ maxWidth: '450px', margin: '10px 0' }}>
                                {Tabstext.username_exists_message[props.lang]}
                            </Typography>
                        }
                        <Divider flexItem >
                            <Typography style={{ fontStyle: 'normal' }}>{Tabstext.OrTab[props.lang]} </Typography>
                        </Divider>
                        </>
                    }
                    {saml_auth === 'true' &&
                        <Typography style={{ maxWidth: '450px', margin: '10px 0' }} >
                            {Tabstext.CompleteRegistrationFormTab[props.lang]}
                        </Typography>
                    }
                    <form onSubmit={handleSubmit} >
                        {saml_auth !== 'true' &&
                            <>
                                <Username
                                    formData={formData}
                                    handleChange={handleChange}
                                    handleUsernameBlur={handleUsernameBlur}
                                    userValidCode={userValidCode}
                                    lang={props.lang}
                                    usernameInputRef={usernameInputRef}
                                />

                                <Password
                                    formData={formData}
                                    handleChange={handleChange}
                                    handlePasswordBlur={handlePasswordBlur}
                                    handleSamePasswordBlur={handleSamePasswordBlur}
                                    lang={props.lang}
                                    passwordInputRef={passwordInputRef}
                                    password2InputRef={password2InputRef}
                                    validPassword={validPassword}
                                    validPassword2={validPassword2}
                                    showPassword={showPassword}
                                    showPassword2={showPassword2}
                                    handleTogglePasswordVisibility={() => setShowPassword(!showPassword)}
                                    handleTogglePassword2Visibility={() => setShowPassword2(!showPassword2)}
                                />
                            </>
                        }
                        <FirstLastName
                            formData={formData}
                            handleChange={handleChange}
                            lang={props.lang}
                        />
                        {!designatedGroup && (
                            <UserTypeSelection
                                formData={formData}
                                handleChange={handleChange}
                                lang={props.lang}
                            />
                        )}

                        {errorMessage && (
                            <Typography color="error" align="center">
                                {errorMessage}
                            </Typography>
                        )}

                        {formData.userType === 'pupil' && (!designatedGroup || errorMessage) && (
                            <GroupSelection
                                formData={formData}
                                handleChange={handleChange}
                                lang={props.lang}
                                allGroupIds={props.allGroupIds}
                                validGroup={validGroup }
                                setValidGroupCallback={setValidGroupCallback}
                            />
                        )}

                        {formData.userType === 'teacher' && (
                            <TeacherSchoolDetails
                                formData={formData}
                                handleChange={handleChange}
                                lang={props.lang}
                            />
                        )}

                        <FormControlLabel
                            control={<Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange} />}
                            label={Tabstext.AgreeTab[props.lang]}
                            required
                        />
                        <ContainedButton
                            type="submit"
                            fullWidth
                        >
                            {Tabstext.SubmitTab[props.lang]}
                        </ContainedButton>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '6px', }}>
                            <Typography >
                                {Tabstext.AlreadyRegisteredTab[props.lang]}
                                <Link to="/login" style={{ color: 'var(--blue-5)' }}>
                                    {Tabstext.SignInTab[props.lang]}
                                </Link>
                            </Typography>
                       </div>
                    </form>
                </Paper>
            </div>
        </div>
    );
}

function Username(props) {
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    type="email"
                    name="username"
                    value={props.formData.username}
                    label={Tabstext.EmailAsUsernameTab[props.lang]}
                    variant="outlined"
                    fullWidth
                    required
                    onBlur={props.handleUsernameBlur}
                    onChange={props.handleChange}
                    inputRef={props.usernameInputRef}
                    dir={lang_dir}
                />
            </div>
            {props.userValidCode && (
                <Typography color='error' style={{ marginBottom: '8px' }}>
                    {props.userValidCode === 'user exists' &&
                        Tabstext.email_exists_message[props.lang]}
                    {props.userValidCode === 'invalid email' &&
                        Tabstext.email_invalid_message[props.lang]}
                </Typography>
            )}
        </>
    );
}

function Password(props) {
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    type={props.showPassword ? "text" : "password"}
                    name="password"
                    value={props.formData.password}
                    label={Tabstext.PasswordTab[props.lang]}
                    variant="outlined"
                    fullWidth
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    required
                    title={Tabstext.password_invalid_message[props.lang]}
                    onBlur={props.handlePasswordBlur}
                    onChange={props.handleChange}
                    inputRef={props.passwordInputRef}
                    dir={lang_dir}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={props.handleTogglePasswordVisibility}
                                    edge="end"
                                >
                                    {props.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {!props.validPassword && (
                <Typography color='error' style={{ marginBottom: '16px' }}>
                    {Tabstext.password_invalid_message[props.lang]}
                </Typography>
            )}
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    type={props.showPassword2 ? "text" : "password"}
                    name="password2"
                    value={props.formData.password2}
                    label={Tabstext.RepeatPasswordTab[props.lang]}
                    variant="outlined"
                    fullWidth
                    required
                    onBlur={props.handleSamePasswordBlur}
                    onChange={props.handleChange}
                    inputRef={props.password2InputRef}
                    dir={lang_dir}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={props.handleTogglePassword2Visibility}
                                    edge="end"
                                >
                                    {props.showPassword2 ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {!props.validPassword2 && (
                <Typography color='error' style={{ marginBottom: '16px' }}>
                    {Tabstext.unequal_password_message[props.lang]}
                </Typography>
            )}
        </>
    );
}

function FirstLastName(props) {
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    type="text"
                    name="firstname"
                    value={props.formData.firstname}
                    label={Tabstext.FirstNameTab[props.lang]}
                    dir={lang_dir}
                    variant="outlined"
                    fullWidth
                    required
                    onChange={props.handleChange}
                />
            </div>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    type="text"
                    name="lastname"
                    value={props.formData.lastname}
                    label={Tabstext.LastNameTab[props.lang]}
                    dir={lang_dir}
                    variant="outlined"
                    fullWidth
                    required
                    onChange={props.handleChange}
                />
            </div>
        </>
    );
}

function UserTypeSelection(props) {
    const { formData, handleChange, lang } = props;
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <div style={{ marginBottom: '8px' }}>
            <RadioGroup
                name="userType"
                value={formData.userType}
                onChange={handleChange}
                row
            >
                <FormControlLabel
                    value="independent"
                    control={<Radio />}
                    label={Tabstext.IamIndependent[lang]}
                />
                <FormControlLabel
                    value="teacher"
                    control={<Radio />}
                    label={Tabstext.IamTeacher[lang]}
                />
                <FormControlLabel
                    value="pupil"
                    control={<Radio />}
                    label={Tabstext.IamSchoolar[lang]}
                />
            </RadioGroup>
        </div>
    );
}

function GroupSelection(props) {
    const [groupName, setGroupName] = useState(null);
    const handleChange = (e) => {
        e.persist();
        props.handleChange(e);
        const { value } = e.target;
        const groupid = parseInt(value)
        setGroupNameFromServer(groupid);
    };

    const setGroupNameFromServer = (groupid) => {
        if (props.allGroupIds.includes(groupid)) {
            axiosInstance.post('/api/get_group_name/', { group_id: groupid })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.group_name) {
                            setGroupName(response.data.group_name);
                            props.setValidGroupCallback(true);
                        }
                        else {
                            setGroupName(Tabstext.NoSuchGroupTab[props.lang]);
                            props.setValidGroupCallback(false);
                        }
                    }
                });
        }
        else {
            setGroupName(Tabstext.NoSuchGroupTab[props.lang]);
            props.setValidGroupCallback(false);
        }
    }

    const groupNameColor = props.validGroup ? 'inherit' : 'red';
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';

    return (
        <>
            <div style={{ marginBottom: '2px' }}>
                <SolfyTextField
                    name="groupid"
                    value={props.formData.groupid}
                    label={Tabstext.YourGroupTab[props.lang]}
                    variant="outlined"
                    dir={lang_dir}
                    fullWidth
                    required
                    onChange={handleChange}
                />
            </div>
            <SmallTitle style={{ fontSize: '12px', marginBottom: '20px', color: groupNameColor }} >
                {groupName}
            </SmallTitle>
        </>
    )
}

function TeacherSchoolDetails(props) {
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    fullWidth
                    type='text'
                    name='school'
                    value={props.formData.school}
                    label={Tabstext.YourSchoolTab[props.lang]}
                    dir={lang_dir}
                    required
                    onChange={props.handleChange}
                />
            </div>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    fullWidth
                    type='text'
                    name='location'
                    value={props.formData.location}
                    label={Tabstext.YourLocationTab[props.lang]}
                    dir={lang_dir}
                    required
                    onChange={props.handleChange}
                />
            </div>
        </>
    );
}

export default RegisterForm;
