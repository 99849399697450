import React, { useState, useEffect } from "react";
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import ToleranceSetting from './tolerance_setting';
import Breadcrumbs from './breadcrumbs';
import './styles/extras.css';
import { HeadTableCell, BodyTableCell, CellLink, PageTitle } from './customized_components';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

function GroupDetails(props) {
    const tableHeight = props.windowHeight * 0.75;
    const [myStudents, setMyStudents] = useState([]);
    const [pitchTolerance, setPitchTolerance] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleStudentSelect = (student) => {
        props.setPageCallback(`${props.group}/${student.username}`, {});
    };

    useEffect(() => {
        if (props.group) {
            // Check if data for the current group is already in localStorage
            const cachedData = localStorage.getItem(`group_${props.group}`);
            if (cachedData) {
                const parsedData = JSON.parse(cachedData);
                setMyStudents(parsedData.students);
                setPitchTolerance(parsedData.pitch_tolerance);
                setLoading(false);
            } else {
                // Fetch from the server if not cached
                setLoading(true);
                axiosInstance
                    .get('/api/group_details/', { params: { group: props.group } })
                    .then((response) => {
                        const dataToCache = {
                            students: Object.values(response.data.studentsInfo),
                            pitch_tolerance: response.data.groupInfo['pitch_tolerance'],
                        };
                        setMyStudents(dataToCache.students);
                        setPitchTolerance(dataToCache.pitch_tolerance);

                        // Cache the data in localStorage
                        localStorage.setItem(`group_${props.group}`, JSON.stringify(dataToCache));
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching group details:', error);
                        setLoading(false);
                    });
            }
        }
    }, [props.group]); // Trigger fetch only when props.group changes

    const setPitchToleranceCallback = (val) => {
        axiosInstance.post('/api/group_settings/', { group: props.group, pitch_tolerance: val }).then(() => {
            setPitchTolerance(val);

            // Update cached data
            const cachedData = localStorage.getItem(`group_${props.group}`);
            if (cachedData) {
                const parsedData = JSON.parse(cachedData);
                parsedData.pitch_tolerance = val;
                localStorage.setItem(`group_${props.group}`, JSON.stringify(parsedData));
            }
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';

    return (
        <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', flex:1 }}>
            <Breadcrumbs />
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", margin: '10px' }}>
                <PageTitle>{props.group}</PageTitle>
                <ToleranceSetting
                    lang={props.lang}
                    setPitchToleranceCallback={setPitchToleranceCallback}
                    feedbackDisplay={false}
                    groupDisplay={true}
                    pitchTolerance={pitchTolerance}
                    pitch_tolerance_range={props.pitch_tolerance_range}
                    tooltipText={Tabstext.SettingsToleranceForGroupTooltip[props.lang]}
                />
            </div>
            <div className='tableWrapper'>
                <TableContainer component={Paper} style={{ boxShadow: 'none', maxHeight: `${tableHeight}px` }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell style={{ borderRadius: '6px 0 0 6px' }}>
                                    {Tabstext.StudentTab[props.lang]}
                                </HeadTableCell>
                                <HeadTableCell>{Tabstext.FirstLastNameTab[props.lang]}</HeadTableCell>
                                <HeadTableCell>{Tabstext.NumRecsTab[props.lang]}</HeadTableCell>
                                <HeadTableCell>{Tabstext.RecsLastMonthTab[props.lang]}</HeadTableCell>
                                <HeadTableCell>{Tabstext.FailedLastMonthTab[props.lang]}</HeadTableCell>
                                <HeadTableCell>{Tabstext.CreatedOnTab[props.lang]}</HeadTableCell>
                                <HeadTableCell>{Tabstext.LastExerciseTab[props.lang]}</HeadTableCell>
                                <HeadTableCell style={{ borderRadius: '0 6px 6px 0' }}>
                                    {Tabstext.CumScoreTab[props.lang]}
                                </HeadTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myStudents.map(([pk, student], index) => (
                                <TableRow key={index}>
                                    <BodyTableCell>
                                        {student.total_recordings > 0 ? (
                                            <CellLink onClick={() => handleStudentSelect(student)}>
                                                <Typography style={{ maxWidth: '10vw' }} className="ellipsis-text">
                                                    {student.username}
                                                </Typography>
                                            </CellLink>
                                        ) : (
                                            <Typography style={{ maxWidth: '10vw' }} className="ellipsis-text">
                                                {student.username}
                                            </Typography>
                                        )}
                                    </BodyTableCell>
                                    <BodyTableCell style={{ maxWidth: '10vw' }} className="ellipsis-text">
                                        {student.fullname}
                                    </BodyTableCell>
                                    <BodyTableCell>{student.total_recordings}</BodyTableCell>
                                    <BodyTableCell>{student.recordings_lastmonth}</BodyTableCell>
                                    <BodyTableCell
                                        style={{
                                            color: student.failed_lastmonth > 10 ? 'red' : '',
                                        }}
                                    >
                                        {student.failed_lastmonth > 10 ? '>10' : student.failed_lastmonth}
                                    </BodyTableCell>
                                    <BodyTableCell style={{ maxWidth: '10vw' }} className="ellipsis-text">
                                        {student.created}
                                    </BodyTableCell>
                                    <BodyTableCell style={{ maxWidth: '15vw' }} className="ellipsis-text">
                                        {student.exercise}
                                    </BodyTableCell>
                                    <BodyTableCell>{student.cum_score}</BodyTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default GroupDetails;
