import React, { useState } from 'react';
import { Select, MenuItem, Tooltip } from '@mui/material';
import Tabstext from './tabstext';

function ZoomSelection(props) {
    const [tooltipVisible, setTooltipVisible] = useState(false); // Control tooltip visibility
    const [isHovered, setIsHovered] = useState(false); // Track mouse hover state

    const handleOpen = () => {
        setTooltipVisible(false); // Hide tooltip when the dropdown opens
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        setTooltipVisible(true); // Show tooltip on hover
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setTooltipVisible(false); // Hide tooltip when not hovered
    };

    const handleZoomChange = (event) => {
        props.setScaleCallback(parseFloat(event.target.value));
    };

    const lang_align = props.lang === 'he' ? 'right' : 'left';

    return (
        <Tooltip
            title={<div style={{ textAlign: lang_align }}>{Tabstext.ZoomTooltip[props.lang]}</div>}
            placement="top"
            open={tooltipVisible} // Explicitly control tooltip visibility
        >
            <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Select
                    variant="standard"
                    style={{ height: '32px' }}
                    value={props.scale}
                    onChange={handleZoomChange}
                    onOpen={handleOpen} // Hide tooltip when dropdown opens                    
                    label="Zoom Level"
                >
                    <MenuItem style={{ fontSize: '12px' }} value={0.6}>50%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={0.7}>70%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={0.8}>80%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={0.9}>90%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={1.0}>100%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={1.1}>110%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={1.2}>120%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={1.3}>130%</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} value={1.4}>150%</MenuItem>
                </Select>
            </span>
        </Tooltip>
    );
}

export default ZoomSelection;
