import React, { useState, useEffect, } from "react";
import { axiosInstance,  } from './axios_instance';
import ExercisePage from './exercise_page';
import Sidebar from './sidebar';
import { Grid, } from '@mui/material';

function ExerciseMain(props) {
    const [exerciseData, setExerciseData] = useState({});
    const [context, setContext] = useState({});
    const [inClass, setInClass] = useState(false);
    const [pk, setPk] = useState(null);

    const setPkCallback = (val) => {
        setPk(val);
    }

    const inClassChangeCallback = (inClass) => {
        setInClass(inClass);
   };

    useEffect(() => {
        if (props.content == 'solfege' || props.content == 'theory' || props.content=='songs') {
            setPk(props.content)
        }
    }, [props.content]);


    useEffect(() => {
        if(pk) {
            axiosInstance.get('/api/exercise/',{'params':{'pk':pk, 'studentUser':null, 'lang':props.lang}})
                .then((response) => {
                    if (response.data) {
                        setExerciseData(response.data);
                        setContext(response.data.context);
                    }
                });
        }
    }, [pk,props.user.as_student]); // retrieve all exercise data when pk changes

    const reloadExerciseData = () => {
        axiosInstance.get('/api/exercise/', { 'params': { 'pk': pk, 'studentUser': null, 'lang': props.lang } })
            .then((response) => {
                if (response.data) {
                    setExerciseData(response.data);
                    setContext(response.data.context);
                }
            });
    };

    if (context==null || Object.keys(context).length === 0 ) {
        return null;
    }
    const sidebarWidth = !inClass ? 220 : 0;

    return (
        <Grid
            style={{ flex: 1, display: 'grid', gridTemplateColumns: !inClass ? `${sidebarWidth}px 1fr` : 'none' }} >
            {!inClass &&
                <Sidebar 
                    {...props}
                    exercise={exerciseData.exe}
                    setPkCallback={setPkCallback}
                    category={context.category}
                    unlockedExercises={context.unlocked_exercises}
                    unlockedLessons={context.unlocked_lessons}
                    firstUnpassedLevel={context.firstUnpassedLevel}
                    firstUnpassedLesson={context.firstUnpassedLesson}
                    firstIncompleteExercise={context.firstIncompleteExercise}
                    firstIncompleteExeLastLesson={context.firstIncompleteExeLastLesson}
                    lastLessonInLevel={context.lastLessonInLevel}
                    firstExerciseLastLesson={context.firstExerciseLastLesson}
                    solfegesPassStatus={context.solfegesPassStatus}
                    user_id={context.user_id}
                    user_group_id={context.user_group_id}
                />
            }
            <ExercisePage
                {...props}
                exerciseData={exerciseData}
                setPkCallback={setPkCallback}
                reloadExerciseData={reloadExerciseData}
                inClass={inClass}
                inClassChangeCallback={inClassChangeCallback}
                sidebarWidth={sidebarWidth}
                >
            </ExercisePage>
        </Grid>
    )
}

export default ExerciseMain;
