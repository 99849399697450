import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tabstext from './tabstext';
import Introtext from './introtext';
import { Typography, Button, Grid } from '@mui/material';
import './styles/index.scss';

function PublicPromo(props) {
    const navigate = useNavigate();
    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';

    return (
        <Grid container direction="row" dir={lang_dir} spacing={4} alignItems="stretch">
            {/* Student Promo Section */}
            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="paleYellowBg" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '40px', height: '100%' }}>
                    <Typography variant="h3" dir={lang_dir}>
                        {Introtext.SolfyForStudents[props.lang]}
                    </Typography>
                    <div className="imageDiv" style={{ width: '300px', height: '400px', margin: '20px 0' }}>
                        <img src="/static/art/PracticingChild_rami.jpg" height="100%" alt="Practicing Child" />
                    </div>
                    <Typography variant="body1" style={{ width: '400px', textAlign: 'center' }} dir={lang_dir} gutterBottom>
                        {Introtext.StudentPromo[props.lang]}
                    </Typography>
                    <Button variant="contained" size="large" className="OrangeBtn" onClick={() => navigate(`/register`)}>
                        {Tabstext.GetStartedTab[props.lang]}
                    </Button>
                </div>
            </Grid>

            {/* Teacher Promo Section */}
            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '40px', height: '100%' }}>
                    <Typography variant="h3" dir={lang_dir}>
                        {Introtext.SolfyForTeachers[props.lang]}
                    </Typography>
                    <div className="imageDiv" style={{ width: '395px', height: '400px', margin: '20px 0' }}>
                        <img src="/static/art/teacherAndPupils_cropped.jpg" height="100%" alt="Teacher and Pupils" />
                    </div>
                    <Typography variant="body1" style={{ width: '600px', textAlign: 'center' }} dir={lang_dir} gutterBottom>
                        {Introtext.TeacherPromo[props.lang]}
                    </Typography>
                    <Button color="secondary" variant="contained" size="large" onClick={() => navigate(`/register`)}>
                        {Tabstext.GetStartedTeacher[props.lang]}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

export default PublicPromo;
