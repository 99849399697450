import React, { useState, useEffect } from "react";
import { axiosInstance } from './axios_instance';
import PracticeRecordingsSelect from './practice_recordings_select';
import ScorePage from './rectest_score_page';
import Tabstext from './tabstext';
import { PutSetFeedbackCallback } from './exercise_utils';
import { ExerciseContainer } from './customized_components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function RecordingTest(props) {
    const [context, setContext] = useState({});
    const [exe, setExe] = useState(null);
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);

    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const navigate = useNavigate(); // Initialize the navigation hook

    const setFeedbackDisplayCallback = (val, data) => {
        if (val && (data || feedbackData)) {
            setFeedbackDisplay(val);
            if (data) {
                setFeedbackData(data);
                setMusicScore(data.score);
            } else {
                setMusicScore(feedbackData.score);
            }
        } else {
            setFeedbackDisplay(false);
            setMusicScore(exe.score);
        }
    };
    PutSetFeedbackCallback(setFeedbackDisplayCallback);

    useEffect(() => {
        axiosInstance.get('/api/exercise/', { 'params': { 'pk': 'recording_test', 'studentUser': null } })
            .then((response) => {
                if (response.data.context) {
                    response.data.context.object_title = Tabstext.RecordingTest[props.lang];
                    setContext(response.data.context);
                    setExe(response.data.exe);
                    setMusicScore(response.data.exe.score);
                    setFeedbackDisplay(false);
                    setFeedbackData(null);
                }
            });
    }, []);

    useEffect(() => {
        setContext(context => ({
            ...context,
            ['object_title']: Tabstext.RecordingTest[props.lang]
        }));
    }, [props.lang]);

    if (context == null || Object.keys(context).length === 0 || exe == null) {
        return (
            <ExerciseContainer>
            </ExerciseContainer>
        );
    }

    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';

    return (
        <ExerciseContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <PracticeRecordingsSelect context={context} lang={props.lang}
                    feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                />
            </div>
            <ScorePage context={context} lang={props.lang}
                exe={exe} musicScore={musicScore} feedbackData={feedbackData}
                feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                windowSize={props.windowSize}
                setUserCallback={props.setUserCallback}
                onExit={props.onExit}

            />
        </ExerciseContainer>
    );
}

export default RecordingTest;
