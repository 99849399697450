import React, { forwardRef } from "react";
import { Button, ToggleButton, Tab, Link, TableCell, Typography, MenuItem, TextField,} from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles/components.scss';
import { useTheme } from '@mui/material/styles';

export const OutlinedButton = forwardRef(({ children, ...props }, ref) => (
    <Button color="primary" variant="outlined" ref={ref} {...props}>
        {children}
    </Button>
));

export const ContainedButton = forwardRef(({ children, ...props }, ref) => (
    <Button color="primary" variant="contained"  ref={ref} {...props}>
        {children}
    </Button>
));

export const ContainedButton2 = forwardRef(({ children, ...props }, ref) => (
    <Button color="secondary" variant="contained" ref={ref} {...props}>
        {children}
    </Button>
));

export const TextButton = forwardRef(({ children, ...props }, ref) => (
    <Button color="primary" variant="text" ref={ref} {...props}>
        {children}
    </Button>
));

export const ForwardArrowButton = forwardRef(({ children, ...props }, ref) => (
    <Button variant="text" ref={ref} {...props} endIcon={<ArrowBackIcon style={{ transform: 'scaleX(-1)', fontSize: '16px' }} />}  >
        {children}
    </Button>
));

export const VerticalTab = (props) => (
    <Tab className="verticalTab" {...props} />
);

export const PracticeTab = (props) => (
    <Tab className="practiceTab"  {...props} />
);

export const LinkButtonTab = (props) => (
    <Tab className="linkButtonTab" {...props} />
);

export const SolfyTextField = ({ dir, ...props }) => {
    return (
        <TextField
            className="solfyTextField"
            {...props}
            InputLabelProps={{
                ...props.InputLabelProps,
                style: {
                    textAlign: dir === 'rtl' ? 'right' : 'left',
                    width: '100%',
                    ...(props.InputLabelProps ? props.InputLabelProps.style : {}),
                },
            }}
        />
    );
};

export const ExerciseContainer = forwardRef(({ children, ...props }, ref) => (
    <div className="exerciseContainer"  ref={ref} {...props}>
        {children}
    </div>
));

export const SolfyTreeView = (props) => (
    <SimpleTreeView className="solfyTreeView" {...props} />
);
export const FirstTreeItem = (props) => (
    <TreeItem className="firstTreeItem" {...props} />
);
export const SecondTreeItem = (props) => (
    <TreeItem className="secondTreeItem" {...props} />
);


export const HeadTableCell = (props) => (
    <TableCell className="headTableCell" {...props} />
);
export const BodyTableCell = (props) => (
    <TableCell className="bodyTableCell" {...props} />
);

export const CellLink = forwardRef(({ children, to, ...props }, ref) => {
    return (
        <Link
            className="cell-link"
            to={to}
            ref={ref}
            {...props} // Forward other props
        >
            {children}
        </Link>
    );
});

export const AudioMenuItem = ({ isLastItem, ...props }) => (
    <MenuItem
        className={`audioMenuItem ${isLastItem ? 'no-border' : ''}`}
        {...props}
    />
);

export const PageTitle = (props) => (
    <Typography variant='h4' className="pageTitle" {...props} />
);

export const SmallTitle = (props) => (
    <Typography variant='h6' className="blue" {...props} />
);

export const FormTitle = (props) => (
    <Typography variant='h4' className="blue" {...props} />
);

export const MediumArrowDropDownIcon = (props) => (
    <ArrowDropDownIcon className="medium-arrow-dropdown-icon" {...props} />
);

export const DynLockIcon = (props) => (
    <LockIcon className="MuiTreeItem-content .MuiTreeItem-iconContainer" style={{ fontSize: '24px' }}  {...props} />
);

export const useH6Styles = () => {
    const theme = useTheme();
    return {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight
    };
};

export const SolfyToggleButton = forwardRef(({ children, className, ...props }, ref) => (
    <ToggleButton
        className={`solfyToggleButton ${className || ''}`} // Merge custom class with incoming className
        ref={ref} {...props}
    >
        {children}
    </ToggleButton>
));
