import React from 'react';
import { useState, useEffect, useRef } from "react";
import { renderScore } from './render_score';
import { moveAudioCursor } from './exercise_utils';

function MusicScore(props) {
    const vfContainerRef = useRef(null); 
    const cursorRef = useRef(null);
    const containerRef = useRef(null);
    const containerWidth = props.windowSize[0] - 150 - props.sidebarWidth;
    const maxContainerHeight = props.windowSize[1] - 200;
    const [containerHeight, setContainerHeight] = useState(maxContainerHeight);

    var musicScore;
    useEffect(() => {
        const vfContainer = vfContainerRef.current; 
        if (vfContainer && props.musicScore) {
            // Clear the previous container content to avoid duplicates
            vfContainer.innerHTML = '';

            if (typeof props.musicScore === 'string')
                musicScore = JSON.parse(props.musicScore);
            else
                musicScore = props.musicScore;

            let height = renderScore(vfContainer, musicScore, cursorRef.current, containerWidth, maxContainerHeight);
            setContainerHeight(height);
            vfContainer.addEventListener("click", moveAudioCursor, false);
            props.canvasRefCallback(vfContainerRef.current, cursorRef.current, containerRef.current);
        }
    }, [props.musicScore, props.windowSize, props.sidebarWidth]);

    return (
        <div
            id="canvas-container"
            ref={containerRef}
            style={{
                display: props.display ? 'block' : 'none',
                position: 'relative',
                height: `${containerHeight}px`,
                maxWidth: containerWidth + 'px',
                clear: 'both',
                backgroundColor: 'white',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <div id="vf-renderer" ref={vfContainerRef}></div> {/* canvas or div for SVG*/}
            <div id="music-cursor" ref={cursorRef} className="music-cursor"></div>
        </div>
    );
}

export default MusicScore;
