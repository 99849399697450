import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import RecordingReminderPopup from './recording_reminder_popup';
import Onboarding from './onboarding';
import RecordingTest from './recording_test';
import StudentDashboard from './student_dashboard';
import TeacherDashboard from './teacher_dashboard';
import ZoomSelection from './zoom_selection';
import AsStudentSelect from './as_student_select';
import { TextButton, } from './customized_components';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';

function Dashboard(props) {

    const lang = props.lang;
    const navigate = useNavigate();
    const location = useLocation();

    const [isRecordingTest, setIsRecordingTest] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [onboarding, setOnboarding] = useState(null);

    const sessionKey = "popupShown"; // Session key to track popup only once after login

    const clearOnboardingCallback = () => {
        setOnboarding(false);
        props.enableTabsCallback(true);
        sessionStorage.setItem(sessionKey, 'true');
    };
    const clearPopCallback = () => {
        setShowPop(false)
        sessionStorage.setItem(sessionKey, 'true');
    }
    const handleRecordingTestClick = () => {
        clearOnboardingCallback();
        setShowPop(false);
        setIsRecordingTest(true);
        navigate('/app/dashboard/recording_test');
    };

    useEffect(() => {
        const isSessionKeySet = sessionStorage.getItem(sessionKey);
        if (!isSessionKeySet) {
            if (props.firstLogin) {
                setOnboarding(true);
                props.enableTabsCallback(false);
            }
            else {
                setShowPop(true);
            }
            //sessionStorage.setItem(sessionKey, 'true'); // how did this caused to reset showPop back to false/null
        }
    }, []);

    //const setAsStudentCallback = (val) => {
    //    axiosInstance.post('/api/user_settings/', { as_student: val }).then();
    //    props.setUserCallback('as_student', val);
    //    //props.setAsStudentCallback(val);
    //};

    useEffect(() => {
        const handlePopState = () => {
            if (location.pathname === '/app/dashboard/recording_test') {
                setIsRecordingTest(true);
            } else {
                setIsRecordingTest(false);
            }
        };
        handlePopState();
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location.pathname]);

    const lang_dir = lang === 'he' ? 'rtl' : 'ltr';

    if (isRecordingTest) {
        return (
            <RecordingTest
                {...props}
                onExit={() => {
                    setIsRecordingTest(false);
                    navigate('/app/dashboard', { replace: true });
                }}
            />
        );
    }
    if (onboarding) {
        return (
            <Onboarding
                {...props}
                clearOnboardingCallback={clearOnboardingCallback}
                handleRecordingTestClick={handleRecordingTestClick}
                user={props.user}
            />
        )
    }

    return (
        <div style={{ flex: 1 }}>
            {props.user.passed_recording_test === 0 && (
                <RecordingReminderPopup
                    showTip={showPop}
                    clearTipCallback={clearPopCallback}
                    handleRecordingTestClick={handleRecordingTestClick}
                    lang={lang}
                />
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px', position: 'relative' }}>
                <TextButton startIcon={<MicIcon />} onClick={handleRecordingTestClick}>
                    <div style={{ marginRight: '10px' }}> {Tabstext.RecordingTest[lang]} </div>
                </TextButton>
                {props.user.teacher && (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <AsStudentSelect
                            lang={lang}
                            user={props.user}
                            setUserCallback={props.setUserCallback}
                        />
                    </div>
                )}
                <ZoomSelection lang={props.lang} scale={props.scale} setScaleCallback={props.setScaleCallback} />
            </div>
            {props.user.as_student && (
                <StudentDashboard lang={props.lang} setContentCallback={props.setContentCallback} />
            )}
            {!props.user.as_student && (
                <TeacherDashboard
                    lang={props.lang}
                    setContentCallback={props.setContentCallback}
                />
            )}
        </div>

    );
}

export default Dashboard;
