import React, { useState } from 'react';
import Tabstext from './tabstext';
import {filterChars} from './exercise_utils';
import instruments_list from '/static/musical_instruments/instruments_list.txt';
import { HeadTableCell, BodyTableCell, CellLink, FirstTreeItem, SecondTreeItem } from './customized_components';
import { Table, TableBody, TableHead, TableRow, } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';


function SolfegeProgressTable(props) {
    const tableHeight = props.windowHeight * 0.75;
    const SolfegeInfo = props.SolfegeInfo;
    const SolfegeLevels = props.loggedSolfegeLevels;

    const last_lesson_id = SolfegeLevels.length > 0 ? SolfegeLevels[SolfegeLevels.length - 1].lesson_set.length - 1 : 0;
    const lastLevelId = `level-${SolfegeLevels.length - 1}`;
    const lastLessonId = `level-${SolfegeLevels.length - 1}-lesson-${last_lesson_id}`;
    const [expanded, setExpanded] = useState([lastLevelId, lastLessonId]);

    const handleToggle = (event, itemIds) => {
        setExpanded(itemIds);
    };
    const levelbadges = ["/static/icons/level1badge.svg", "/static/icons/level2badge.svg", "/static/icons/level3badge.svg"];

    const musicalInstrumentsList = instruments_list.split('\n').map(line => line.trim()).filter(line => line);
    var instruments = [];
    var index = 0;
    for (let i = 0; i < SolfegeLevels.length; i++) {
        instruments.push([]);
        for (let j = 0; j < SolfegeLevels[i].lesson_set.length; j++) {
//            instruments[i].push('/static/musical_instruments/' + musicalInstrumentsList[index]); // ok too
            instruments[i].push(`/static/musical_instruments/${musicalInstrumentsList[index]}`); // better
            index += 1;
        }
    }

    return (
        <div style={{ boxShadow: 'none', maxHeight: `${tableHeight}px`, overflowY: 'auto', display: props.display }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <HeadTableCell style={{ borderRadius: '6px 0 0 6px', width: '40%', textAlign: 'left' }} ></HeadTableCell>
                        <HeadTableCell style={{ width: '30%', textAlign: 'left' }} >
                            {Tabstext.RecordingsTab[props.lang]}
                        </HeadTableCell>
                        <HeadTableCell style={{ borderRadius: '0 6px 6px 0', width: '30%', textAlign: 'left' }}>
                            {Tabstext.ScoreTab[props.lang]}
                        </HeadTableCell>
                        <HeadTableCell style={{ borderRadius: '0 6px 6px 0', width: '30%', textAlign: 'left' }}>
                            {Tabstext.BadgeTab[props.lang]}
                        </HeadTableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <SimpleTreeView
                expandedItems={expanded}
                onExpandedItemsChange={handleToggle}
                >
                {SolfegeLevels.map((level, i) => (
                    <FirstTreeItem key={i} itemId={`level-${i}`}
                        label={
                            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                {filterChars(level.title,props.lang)}
                                {i<=SolfegeLevels.length-2 &&
                                    <img src={levelbadges[i]} alt="Image" width="50" height="50" style={{marginRight:'5px'}} />
                                }
                            </div>
                        }
                        >
                        {level.lesson_set.map((lesson, j) => (
                            <SecondTreeItem itemId={`level-${i}-lesson-${j}`} key={`level-${i}-lesson-${j}`}
                                label={
                                    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                        {lesson.title}
                                        <img src={instruments[i][j]} alt="Image" width="30" height="30" style={{marginRight:'10px'}} />
                                    </div>
                                }
                                >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {lesson.exercise_set.map((exe, k) => (
                                            <TableRow key={`level-${i}-lesson-${j}-exe${k}`}>
                                                <BodyTableCell style={{ width: '35%', textAlign: 'left' }} >
                                                    {props.teacherReview && (
                                                        <CellLink onClick={() => props.handleExeSelect(exe, 'solfege')}>
                                                            {exe.title}
                                                        </CellLink>
                                                    )}
                                                    {!props.teacherReview && exe.title}
                                                </BodyTableCell>
                                                <BodyTableCell style={{ width: '30%', textAlign: 'left' }} >{SolfegeInfo[exe.pk].num_recs}</BodyTableCell>
                                                <BodyTableCell style={{ width: '30%', textAlign: 'left' }} >{SolfegeInfo[exe.pk].performance_score}</BodyTableCell>
                                                <BodyTableCell style={{ width: '30%', textAlign: 'left' }} ></BodyTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </SecondTreeItem>
                        ))}
                    </FirstTreeItem>
                ))}
            </SimpleTreeView>
        </div>
    );
}

export default SolfegeProgressTable;

