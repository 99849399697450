import React from 'react';
import Fragment from './Fragment';

function Row({ row, onDrop, isSolved, playSegment }) {
    const onDropFragment = (e, insertIndex = null) => {
        const fragment = JSON.parse(e.dataTransfer.getData('fragment')); // Retrieve and parse the fragment
        onDrop(fragment, insertIndex);
    };

    const onDragOver = (e, index) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    };

    const onDragStart = (e, fragment) => {
        if (!isSolved) {
            e.dataTransfer.setData('fragment', JSON.stringify(fragment)); // Store the fragment being dragged as string
        }
    };

    return (
        <div
            className={`row ${isSolved ? 'solved-row' : ''}`} // Apply the solved-row class if the level is solved
        >
            <h4 className="puzzle-h4">Row {row.row}</h4>
            {row.fragments.map((fragment, index) => (
                <div
                    key={index}
                    className={`fragment ${isSolved ? 'no-border' : ''}`} // Apply no-border class to remove fragment borders
                    onDragOver={(e) => onDragOver(e, index)}
                    onDrop={(e) => onDropFragment(e, index)}
                >
                    <Fragment
                        fragment={fragment}
                        onDragStart={(e) => onDragStart(e, fragment)}
                        playSegment={playSegment}
                        isDraggable={!isSolved} // Disable dragging if the level is solved
                    />
                </div>
            ))}

            <div
                className="drop-zone stretch"
                onDragOver={(e) => onDragOver(e, row.fragments.length)}
                onDrop={(e) => onDropFragment(e, row.fragments.length)}
            >
            </div>
        </div>
    );
}

export default Row;
