import React, { useState } from "react";
import { Grid, Tabs } from '@mui/material';
import { ExerciseContainer, VerticalTab } from '../customized_components';

import Puzzle from './puzzle';

function Games(props) {
    const [value, setValue] = useState("Level1_Lesson01");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid
            style={{ flex: 1, display: 'grid', gridTemplateColumns: '200px 1fr' }}
        >
            <div className="sidebar">
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        indicatorColor="transparent"
                        style={{ borderRight: 'none', marginTop: '40px' }}
                    >
                        <VerticalTab label="Puzzle" value="Level1_Lesson01" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson02" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson03" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson04" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson05" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson06" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson07" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson08" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson09" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson10" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson11" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson12" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson13" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson14" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson15" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson16" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson17" />
                        <VerticalTab label="Puzzle" value="Level1_Lesson18" />

                    </Tabs>
                </div>
            </div>
            <ExerciseContainer>
                {/* Use the `value` as the key to force re-rendering */}
                <Puzzle key={value} puzzleFolder={value} />
            </ExerciseContainer>
        </Grid>
    );
}

export default Games;
