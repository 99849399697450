import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import ScorePage from './score_page';
import RunsHistory from './runs_history';
import PracticeRecordingsSelect from './practice_recordings_select';
import ZoomSelection from './zoom_selection';
import {PutSetFeedbackCallback} from './exercise_utils';
import Breadcrumbs from './breadcrumbs';
import { ExerciseContainer, PageTitle, ForwardArrowButton } from './customized_components';


function ExerciseDetails(props) {
    const [context, setContext] = useState(null);
    const [runs_dict, setRunsDict] = useState(null);
    const [exeTitle, setExeTitle] = useState(null);
    const [exe, setExe] = useState(null);
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const setFeedbackCallback = (val,data) => {
        setFeedbackData(data);
        setMusicScore(data.score);
    };
    PutSetFeedbackCallback(setFeedbackCallback);

    const [reanalyzeTrig, setReanalyzeTrig] = useState(false);
    const setReanalyzeTrigCallback = (val) => {
        setReanalyzeTrig(val);
    };
    const [exerciseView, setExerciseView] = useState('runs_table');
    const setExerciseViewCallback = (val) => {
        setExerciseView(val);
        if (val == 'score')
            axiosInstance.get('/api/exercise_details/', { 'params': { 'pk': props.pk, 'studentUser': props.username } })
                .then((response) => {
                    if (response.data.context) {
                        setContext(response.data.context);
                        setExe(response.data.exe);
                        //setRunsDict(response.data.runs_dict);
                    }
                })
                .catch((error) => {
                    console.log('get exercise error:', error);
                    props.setPageCallback(`${props.group}/${props.username}`, {});
                });
           setFeedbackDisplay(true);
    };

    useEffect(() => {
        axiosInstance.get('/api/runs_history/',{'params':{'pk':props.pk,'studentUser':props.username}})
            .then((response) => {
                if (response.data) {
                    setExeTitle(response.data.exe_title);
                    setRunsDict(response.data.runs_dict);
                }
            })
            .catch((error) => {
                console.log('get runs_history error:', error);
                props.setPageCallback(`${props.group}/${props.username}`, {});
            });
    }, []);

    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const setFeedbackDisplayCallback = (val, temp_fdbk) => {
        setFeedbackDisplay(val);
        setExerciseView('score');
        if (val) {
            if (temp_fdbk) {
                setFeedbackData(temp_fdbk);
                setMusicScore(temp_fdbk.score);
            }
            else
                setMusicScore(feedbackData.score);
        }
        else
            setMusicScore(exe.score);
   };
    const setMusicScoreCallback = (data) => {
        setMusicScore(data.score);
    }

    const runsTableWidth = props.windowSize[0] - 250;

    if (exerciseView === 'runs_table' && runs_dict) {
        return (
            <div style={{ margin: '10px 20px', overflowY: 'auto', flex:1 }} >
                <Breadcrumbs />
                <PageTitle style={{ margin: '10px' }} >
                    {exeTitle}
                </PageTitle>
                <RunsHistory  
                    {...props}
                    exe={exe}
                    runs_dict={runs_dict}
                    setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    setExerciseViewCallback={setExerciseViewCallback}
                    runsTableWidth={runsTableWidth}
                    display={true}
                />
            </div>
        );
    }

    else if (exerciseView == 'score' && context) {
        return (
            <ExerciseContainer >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PracticeRecordingsSelect context={context} lang={props.lang} reviewMode={true}
                            feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                        />
                        {feedbackDisplay && feedbackData &&
                            <AllRunsSelect lang={props.lang}
                                exerciseView={exerciseView} setExerciseViewCallback={setExerciseViewCallback}
                            />
                        }
                    </div>
                    <ZoomSelection scale={props.scale} setScaleCallback={props.setScaleCallback} />
                </div>
                <ScorePage context={context} lang={props.lang}
                    {...props}
                    exe={exe} musicScore={musicScore}
                    inExercisePage={false} feedbackData={feedbackData}
                    inClass={false}
                    feedbackPresent={true} setFeedbackPresentCallback={[]}
                    feedbackDisplay={true} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    setExerciseViewCallback={setExerciseViewCallback}
                    reanalyzeTrig={reanalyzeTrig} setReanalyzeTrigCallback={setReanalyzeTrigCallback}
                    setMusicScoreCallback={setMusicScoreCallback}
                    sidebarWidth={0}
                    recordingsOnly={true}
                />
            </ExerciseContainer>
        );
    }

    else
        return null;
}

function AllRunsSelect(props) {
    const lang = props.lang;
    const handleButtonClick = () => {
        props.setExerciseViewCallback('runs_table');
    };
    return (
        <ForwardArrowButton onClick={handleButtonClick} >
            {Tabstext.all_recordings_tab[lang]}
        </ForwardArrowButton>
    )
}

export default ExerciseDetails;

