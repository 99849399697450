import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import UserProfile from './user_profile';
import { OutlinedButton, FormTitle, } from './customized_components';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Paper, Typography } from '@mui/material';
import { ReactComponent as UserIcon } from '/static/icons/user.svg';

function User(props) {
    const lang = props.lang;
    const [reload, setReload] = useState(true);
    const [view, setView] = useState('user');
    const resetViewCallback = (val) => {
        setView('user');
        setReload(val);
    };

    const navigate = useNavigate();

    const Logout = () => {
        axiosInstance.post('/api/logout/')
            .then((response) => {
                console.log('Logout response:', response.data);
                props.setAuthenticatedCallback(false);
                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;
                }
            })
            .catch((error) => {
                console.error('Logout error:', error);
            });
    };

    const lang_dir = lang == 'he' ? 'rtl' : 'ltr';

    if (props.user == null)
        return null;

    return (
        <div 
            dir={lang_dir}
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                margin:'40px',
            }}
        >
            <div  style={{marginBottom:'12px'}}>
                <UserIcon />
            </div>
            <FormTitle gutterBottom> {props.user.username} </FormTitle>
            { view =='user' &&
                <div dir={lang_dir}  >
                    <Paper className='shortPaper' style={{marginBottom:'10px'}} >
                        <Typography style={{ padding: '20px' }}>{Tabstext.ProfileUpdate[lang]}</Typography>                        
                            <OutlinedButton startIcon={<PersonOutlineIcon style={{marginLeft:'10px'}} />} onClick={() => setView('profile_setting')} >
                            {Tabstext.ProfileTab[lang]}
                        </OutlinedButton>
                    </Paper>
                    <OutlinedButton onClick={() => Logout(true)} >
                        {Tabstext.SignOutTab[props.lang]}
                    </OutlinedButton>
                </div>
            }
            {view == 'profile_setting' &&
                <UserProfile lang={props.lang} windowSize={props.windowSize}
                    user={props.user}
                    setUserCallback={props.setUserCallback}
                    resetViewCallback={resetViewCallback}
                    allGroupIds={props.allGroupIds}
                />
            }
        </div>
    );
}

export default User;