import React, { useState } from "react";
import Tabstext from './tabstext';
import { MediumArrowDropDownIcon } from './customized_components';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import ReactCountryFlag from "react-country-flag";

function LangSelect(props) {
    const lang_options = props.lang_options;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectLang = (val) => {
        if (val !== props.lang) {
            props.langChangeCallback(val);
        }
        handleCloseMenu();
    };

    const popperProps = {
        modifiers: [
            { name: 'offset', options: { offset: [0, -10] } }
        ]
    };

    return (
        <div>
            <Button
                id="lang-select-button"
                variant="text"
                onClick={handleOpenMenu}
                endIcon={<MediumArrowDropDownIcon />}
            >
                <ReactCountryFlag
                    countryCode={lang_options[props.lang]}
                    style={{
                        width: '32px',
                        height: '32px',
                    }}
                    svg
                />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {Object.entries(lang_options).map(([lang, countryCode], index) => (
                    <Tooltip title={Tabstext.LanguageName[props.lang][lang]} key={index} placement="right" PopperProps={popperProps}>
                        <MenuItem
                            value={lang}
                            onClick={() => handleSelectLang(lang)}
                        >
                            <ReactCountryFlag
                                countryCode={countryCode}
                                style={{
                                    width: '32px',
                                    height: '32px',
                                }}
                                svg
                            />
                        </MenuItem>
                    </Tooltip>
                ))}
            </Menu>
        </div>
    );
}

export default LangSelect;
