// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --blue-1: #E0EFFA;
  --blue-2: #D0E6F7;
  --blue-3: #90C5EF;
  --blue-4: #4DA2E5;
  --blue-5: #17619C;
  --blue-7: #194B5B;
  --coral: coral;
  --dark-orange: #E4623A;
  --yellow-dark: #FA9C05;
  --yellow-light: #FEDC98;
  --grey-3: #818589;
}

.blue-5 {
  fill: #17619C;
}`, "",{"version":3,"sources":["webpack://./solfy-fe/styles/colors.scss"],"names":[],"mappings":"AAsCA;EACI,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;AArCJ;;AAwCA;EACI,aA7CK;AAQT","sourcesContent":["// colors.scss\r\n\r\n$neutral: #FFFFFF;\r\n$faint-bgrd: #F2F6F8;\r\n$blue-1: #E0EFFA;\r\n$blue-2: #D0E6F7;\r\n$blue-3: #90C5EF;\r\n$blue-4: #4DA2E5;\r\n$blue-5: #17619C;\r\n$blue-6: #0D548C;\r\n$blue-7: #194B5B;\r\n$electric-5: #292A85;\r\n$yellow-light: #FEDC98;\r\n$yellow: #FBAF34;\r\n$yellow-dark: #FA9C05;\r\n$coral: #FB6A6B;\r\n$bright-orange: #FD7348;\r\n$dark-orange: #E4623A;\r\n$cream-1: #FEFDF9;\r\n$cream-2: #FFF9EB;\r\n$beige-1: #FDEECB;\r\n$beige-4: #955B00;\r\n$grey-1: #F5F5F5;\r\n$grey-2: #9CA0A8;\r\n$grey-3: #818589;\r\n$grey-4: #2A2C30;\r\n$green-1: #CAE9DC;\r\n$green-2: #A8EEC1;\r\n$green-3: #97D6AD;\r\n$green-4: #008060;\r\n$green-5: #00583A;\r\n$red-1: #FFD6D2;\r\n$red-2: #FF8985;\r\n$red-3: #D3474D;\r\n$red-4: #8A1D27;\r\n$text-primary: #2A2C35;\r\n\r\n// Convert SCSS variable to CSS variable\r\n:root {\r\n    --blue-1: #{$blue-1};\r\n    --blue-2: #{$blue-2};\r\n    --blue-3: #{$blue-3};\r\n    --blue-4: #{$blue-4};\r\n    --blue-5: #{$blue-5};\r\n    --blue-7: #{$blue-7};\r\n    --coral: #{coral};\r\n    --dark-orange: #{$dark-orange};\r\n    --yellow-dark: #{$yellow-dark};\r\n    --yellow-light: #{$yellow-light};\r\n    --grey-3: #{$grey-3};\r\n}\r\n\r\n.blue-5 {\r\n    fill: $blue-5;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
