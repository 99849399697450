import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance,  } from './axios_instance';
import Tabstext from './tabstext';
import SolfegeProgressTable from './solfege_progress_table';
import TheoryProgressTable from './theory_progress_table';
import SongsProgressTable from './songs_progress_table';
import Breadcrumbs from './breadcrumbs';
import { Menu, MenuItem, Button } from '@mui/material';
import { PageTitle, AudioMenuItem, OutlinedButton, MediumArrowDropDownIcon } from './customized_components';


function StudentDetails(props) {
    const [solfegeInfo, setSolfegeInfo] = useState(null);
    const [theoryInfo, setTheoryInfo] = useState(null);
    const [songInfo, setSongInfo] = useState(null);
    const [loggedSolfegeLevels, setLoggedSolfegeLevels] = useState(null);

    const [state, setState] = useState(null);
    const [states, setStates] = useState(null);

    const handleSelect = (val) => {
        setState(val);
    };

    useEffect(() => {
        axiosInstance.get('/api/student_details/', { 'params': { 'username': props.username } })
            .then((response) => {
                setSolfegeInfo(response.data.solfegeInfo);
                setTheoryInfo(response.data.theoryInfo);
                setSongInfo(response.data.songsInfo);
                truncateSolfegeLevels(response.data.level_ids, response.data.lesson_ids, response.data.solfege_ids);
                //selectInitialState(response.data.solfegeInfo, response.data.theoryInfo, response.data.songsInfo)
            })
            .catch((error) => {
                console.log('get student error:', error);
                props.setPageCallback('group_details', { group: props.group });
            });
    }, [props.SolfegeLevels]); // end useEffect

    const truncateSolfegeLevels = (level_ids, lesson_ids, solfege_ids) => {
        var truncLevels = truncateObject(props.SolfegeLevels, level_ids);
        truncLevels = JSON.parse(JSON.stringify(truncLevels));
        for (const level of truncLevels) {
            let trunc_lesson_set = truncateObject(level.lesson_set, lesson_ids);
            level.lesson_set = trunc_lesson_set;
            for (const lesson of trunc_lesson_set) {
                let trunc_exercise_set = truncateObject(lesson.exercise_set, solfege_ids);
                lesson.exercise_set = trunc_exercise_set;
            }
        }
        setLoggedSolfegeLevels(truncLevels);
    };

    useEffect(() => {
        if (solfegeInfo) {
            const values = [Object.keys(solfegeInfo).length, theoryInfo.length, songInfo.length];
            let maxIndex = 0;
            for (let i = 1; i < values.length; i++) {
                if (values[i] > values[maxIndex]) {
                    maxIndex = i;
                }
            }
            setStates([Tabstext.SolfegeTab[props.lang], Tabstext.TheoryTab[props.lang], Tabstext.SongsTab[props.lang]]);
            setState(maxIndex == 0 ? Tabstext.SolfegeTab[props.lang] : (maxIndex == 1 ? Tabstext.TheoryTab[props.lang] : Tabstext.SongsTab[props.lang]));
        }
    }, [solfegeInfo, props.lang]);

    const handleExeSelect = (exe, category) => {
        props.setPageCallback(`${props.group}/${props.username}/${exe.pk}`, {});
    };

    if (solfegeInfo==null || loggedSolfegeLevels==null || states==null) {
        return <div></div>;
    }

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
        <div style={{ overflowY: 'auto', display: 'flex', flexDirection:'column', flex:1, }} >
            <Breadcrumbs />
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", margin: '10px' }} >
                <PageTitle >
                    {props.username}
                </PageTitle>
                <StateSelect
                    states={states}
                    state={state}
                    setStateCallback={handleSelect}
                    lang={props.lang}
                />
            </div>
            <div className='tableWrapper' style={{ }}>
                <SolfegeProgressTable
                    lang={props.lang} windowHeight={props.windowHeight}
                    SolfegeInfo={solfegeInfo} handleExeSelect={handleExeSelect}
                    loggedSolfegeLevels={loggedSolfegeLevels}
                    teacherReview = {props.teacherReview}
                    group={props.group} display={state==states[0]?'':'none'}
                />
                <TheoryProgressTable
                    lang={props.lang} windowHeight={props.windowHeight}
                    theoryInfo={theoryInfo} handleExeSelect={handleExeSelect}
                    teacherReview = {props.teacherReview}
                    group={props.group} display={state == states[1] ?'':'none'}
                />
                <SongsProgressTable
                    lang={props.lang} windowHeight={props.windowHeight}
                    songInfo={songInfo} handleExeSelect={handleExeSelect}
                    teacherReview = {props.teacherReview}
                    group={props.group} display={state == states[2] ?'':'none'}
                />
            </div>
        </div>

    );
}

export default StudentDetails;

function truncateObject(originalObject, target_ids) {
    const truncatedObject = [];
    for (let key in originalObject) {
        if (target_ids.includes(originalObject[key].pk)) {
            truncatedObject.push(originalObject[key]);
        }
    }
    return truncatedObject;
}


function StateSelect(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectState = (val) => {
        if (val !== props.state) {
            props.setStateCallback(val);
        }
        handleCloseMenu();
    };

    return (
        <div style={{ margin: '0 24px' } }>
            <Button
                id="state-select-button"
                variant="contained"
                onClick={handleOpenMenu}
                endIcon={<MediumArrowDropDownIcon />}
                style={{minWidth:'150px'}}
            >
                {props.state}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', 
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center', 
                }}
                >
                {props.states.map((t, index) => (
                    <AudioMenuItem
                        value={t}
                        onClick={() => handleSelectState(t)}
                        key={index}
                    >
                        {t}
                    </AudioMenuItem>
                ))}
            </Menu>
        </div>
    );
}