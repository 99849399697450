import Messages from './messages';
import React, { useState, useEffect } from 'react';
import { Button, Typography, Checkbox, Alert, AlertTitle } from '@mui/material';
import Tabstext from './tabstext';
import { axiosInstance } from "./axios_instance";

function Tip(props) {
    const [showTip, setShowTip] = useState(false); // Controls whether the tip is visible
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    useEffect(() => {
        if (Object.keys(props.user.tips_status).includes(props.tipKey))
            setShowTip(props.user.tips_status[props.tipKey]['show'])
        else
            setShowTip(true);
    }, [props.tipKey]);

    const tiptext = Messages[props.text][props.lang];
    const tiptext2 = props.text2 ? Messages[props.text2][props.lang] : null;
    const dontShowAgain = Tabstext.DontShowAgain[props.lang];
    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const showState = !e.target.checked;
        axiosInstance
            .post(`/api/user-tips/${props.tipKey}/`, { show: showState })
            .then(() => {
                console.log("Don't show again preference saved.");
            })
            .catch((error) => {
                console.error("Error saving preference:", error);
            });
        props.setUserCallback({ 'tips_status': { [props.tipKey]: showState } })
    };

    const handleClose = () => {
        setShowTip(false);
    };

    if (!showTip) {
        return null;
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.25)', // Optional background overlay
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1300, // Ensure it appears above other elements
            }}
        >
            <div style={{ width: '50%' }}>
                <Alert
                    severity="info"
                    dir={lang_dir}
                    icon={false}
                >
                    <AlertTitle
                        dir={lang_dir}
                        style={{
                            textAlign: 'justify',
                            display: 'block',
                            alignItems: 'center',
                            margin:'4px',
                        }}
                    >
                        <p>{tiptext}</p>
                        <p>{tiptext2}</p>
                    </AlertTitle>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <Checkbox
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                    size="small"
                                />
                                <Typography component="span" dir={lang_dir} style={{ marginLeft: '8px' }}>
                                    {dontShowAgain}
                                </Typography>
                            </label>                        </div>
                        <Button size="small" onClick={handleClose}>
                            {Tabstext.Close[props.lang]}
                        </Button>
                    </div>
                </Alert>
            </div>
        </div>
    );
}

export default Tip;
