import React from 'react'
import { useState } from "react";
import RectestAudio from './rectest_audio';
import TitleAndSongSelections from './title_song';
import MusicScore from './music_score';

function ScorePage(props) {
    const context = props.context;
    const exe = props.exe;
    const [canvasRef, setCanvasRef] = useState(null)
    const canvasRefCallback = (vf,cursor,shadow,container) => {
        setCanvasRef([vf,cursor,shadow,container]);
    }

    //if ((props.feedbackDisplay && !props.feedbackData))
    //    return null;

    return (
        <div style={{ overflowY:'hidden'}}>            
            <RectestAudio exe={exe} lang={props.lang}
                tempo={context.tempos[0]}
                feedbackData={props.feedbackData}
                feedbackDisplay={props.feedbackDisplay} setFeedbackDisplayCallback={props.setFeedbackDisplayCallback}
                handlePlaybackEndCallback={null}
                canvasRef={canvasRef}
                setUserCallback={props.setUserCallback}
                onExit={props.onExit}
            />
            <div style={{ backgroundColor:'white', marginTop: '12px' }}>
                <TitleAndSongSelections context={context} exe={exe} lang={props.lang}
                    exerciseView={'score'}
                    nextExe={null} prevExe={null} setPkCallback={null}
                    nextEnabled={false} prevEnabled={false}
                    feedbackDisplay={props.feedbackDisplay}
                    feedbackData={null}
                    inClass={false} recTest={true}
                    />
            </div>
            <MusicScore
                musicScore={props.musicScore} canvasRefCallback = {canvasRefCallback}
                windowSize={props.windowSize}
                sidebarWidth = {0}
                display = {true}
                />
        </div>
    );
};

export default ScorePage