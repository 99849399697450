import React from 'react'
import { useState, useEffect } from "react";
import {filterChars} from './exercise_utils';
import { SolfyTreeView, FirstTreeItem, SecondTreeItem, } from './customized_components'
import Tip from './tip';
import { Button, } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './styles/extras.css';
import './styles/colors.scss';
import { CustomTypography } from './styled_components';


function Sidebar(props) {
    const SolfegeLevels = props.SolfegeLevels;
    const TheoryCourses = props.TheoryCourses;
    const SongClasses = props.SongClasses;
    const TreeOffset = 1000;

    //const defaultExpandedNodes = [String(props.exe.course_pk), String(props.exe.lesson_pk + TreeOffset)];
    const [expanded, setExpanded] = useState([String(props.exercise.course_pk), String(props.exercise.lesson_pk + TreeOffset)]);
    useEffect(() => {
        setExpanded([String(props.exercise.course_pk), String(props.exercise.lesson_pk + TreeOffset)]);
    }, [props.exercise]); // Add props.pk as a dependency

    const handleToggle = (event, itemIds) => {
        // there should be no more than two expanded branches, one course and one lesson
        if (itemIds.length == 2) {
            if (itemIds[0] < TreeOffset && itemIds[1] < TreeOffset || itemIds[0] > TreeOffset && itemIds[1] > TreeOffset)
                setExpanded([itemIds[0]]);
            else
                setExpanded(itemIds);
        }
        else if (itemIds.length == 3) {
            if (itemIds[0] < TreeOffset) {
                if (itemIds[1] < TreeOffset)
                    setExpanded([itemIds[0], itemIds[2]]);
                else
                    setExpanded([itemIds[0], itemIds[1]]);
            }
            else {
                if (itemIds[1] > TreeOffset)
                    setExpanded([itemIds[0], itemIds[2]]);
                else
                    setExpanded([itemIds[0], itemIds[1]]);
            }
        }
        else
            setExpanded(itemIds);
    };

    if (SolfegeLevels === null) {
        return null;
    }
    return (
        <div className='sidebar' >
            {props.category === 'solfege' &&
                <SolfegeTree SolfegeLevels={SolfegeLevels} lang={props.lang}
                    {...props}
                    expanded={expanded}
                    TreeOffset={TreeOffset}
                    handleToggle={handleToggle}
                    />
            }
            {props.category == 'theory' &&
                <TheoryTree TheoryCourses={TheoryCourses} lang={props.lang}
                {...props}
                    expanded={expanded}
                    TreeOffset={TreeOffset}
                    handleToggle={handleToggle}
                />
            }
            {props.category == 'songs' &&
                <SongsTree SongClasses={SongClasses} lang={props.lang}
                    {...props}
                    expanded={expanded}
                    TreeOffset={TreeOffset}
                    handleToggle={handleToggle}
                    />
            }
        </div>
    );
}

function SolfegeTree (props) {
    const SolfegeLevels = props.SolfegeLevels;
    const user = props.user;
    const unlockedLessons = props.unlockedLessons;

    const unlockedLessonsP1 = unlockedLessons.map(x => x + 1);
    const unlockedLessons1 = [...new Set([...unlockedLessons, ...unlockedLessonsP1])];

    const tipKey = user.as_student ? 'solfege' : 'solfege_teacher';
    const tipText = user.as_student ? 'solfege_progress_scheme' : 'solfege_progress_teachers';
    const tipText2 = 'explore_buttons';

    //useEffect(() => {
    //    console.log('SolfegeTree mounted');
    //}, []);

    return (
        <>
        <Tip {...props} tipKey={tipKey} text={tipText} text2={tipText2} />
        <SolfyTreeView
            expandedItems={props.expanded}
            onExpandedItemsChange={props.handleToggle}>
            {SolfegeLevels.map((course) => {
                const courseLabel = (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                        {filterChars(course.title, props.lang)}
                    </div>
                );

                return (
                    <FirstTreeItem itemId={String(course.pk)} key={course.pk} label={courseLabel}>
                        {course.lesson_set.map((lesson) => {
                            const isLocked = user.as_student && !unlockedLessons.includes(lesson.serial_number);
                            const isHidden = user.as_student && !unlockedLessons1.includes(lesson.serial_number);
                            const lessonLabel = (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                    {isLocked ? '...' : lesson.title}
                                    {isLocked && <LockIcon style={{ fontSize: '16px' }} />}
                                </div>
                            );

                            return (
                                <SecondTreeItem
                                    itemId={String(props.TreeOffset + lesson.pk)}
                                    key={`lesson-${course.pk}-${lesson.pk}`}
                                    disabled={isLocked}
                                    label={lessonLabel}
                                    style={{ color: lesson.pk == props.exercise.lesson_pk ? 'var(--dark-orange)' : 'var(--blue-5)', display: isHidden ? 'none' : '' }}
                                    >
                                    {lesson.exercise_set.map((exe) => (
                                        <SolfegeExerciseButton key={`exercise-${course.pk}-${lesson.pk}-${exe.pk}`}
                                            {...props}
                                            course={course}
                                            lesson={lesson}
                                            exe={exe}
                                            unlockedLessons={unlockedLessons}
                                            >
                                        </SolfegeExerciseButton>
                                    ))}
                                </SecondTreeItem>
                            );
                        })}
                    </FirstTreeItem>
                );
            })}
        </SolfyTreeView>
        </>
    );
}


function TheoryTree(props) {
    const TheoryCourses = props.TheoryCourses;
    //useEffect(() => {
    //    console.log('SolfegeTree mounted');
    //}, []);
    return (
        <SolfyTreeView
            expandedItems={props.expanded}
            onExpandedItemsChange={props.handleToggle}
        >
            {TheoryCourses.map((course, i) => (
                <FirstTreeItem itemId={String(course.pk)} key={course.pk}
                    label={
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            {filterChars(course.title, props.lang)}
                        </div>
                    }
                    >
                    <Tip {...props} tipKey={'intervals'} text={'intervals_explained'} text2={'explore_buttons'}  />
                    {course.lesson_set.map((lesson, j) => (
                        <SecondTreeItem
                            itemId={String(props.TreeOffset + lesson.pk)} key={course.pk * 100 + lesson.pk}
                            label={lesson.title}
                            style={{ color: lesson.pk == props.exercise.lesson_pk ? 'var(--dark-orange)' : 'var(--blue-5)', }}
                        >
                        {lesson.exercise_set.map((exe, k) => (
                            <Button className="TreeViewBtn"
                                id={String(course.pk * 100 + lesson.pk * 1000 + exe.pk)}
                                key={course.pk * 100 + lesson.pk * 1000 + exe.pk}
                                onClick={() => props.setPkCallback(exe.pk)}
                                style={{backgroundColor: exe.pk == props.exercise.pk ? 'white' : 'transparent',}}
                                >
                                <p className="ellipsis-text" style={{ textTransform: 'none', fontSize: '13px', fontWeight: '550' }}>
                                    {exe.title}
                                </p>
                            </Button>
                        ))}
                        </SecondTreeItem>
                    ))}
                </FirstTreeItem>
            ))}
        </SolfyTreeView>
    );
}


function SongsTree(props) {
    const SongClasses = props.SongClasses;
//    document.body.dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <SolfyTreeView
            aria-label="file system navigator"
            expandedItems={props.expanded}
            onExpandedItemsChange={props.handleToggle}
            >
            {SongClasses.map((course, i) => (
                <FirstTreeItem itemId={String(course.pk)} key={`${i}a`}
                label={filterChars(course.title,props.lang)}
                >
                    <Tip {...props} tipKey={'songs'} text={'explore_songs_buttons'} />
                   {course.lesson_set.map((lesson, j) => (
                        <SecondTreeItem itemId={String(props.TreeOffset+lesson.pk)} key={`${i*100+j}d`}
                        label={lesson.title}
                            style={{ color: lesson.pk == props.exercise.lesson_pk ? 'var(--dark-orange)' : 'var(--blue-5)' }}
                        >
                        {lesson.songlesson_set.map((song, k) => (
                            <SongButton id={String(course.pk * 100 + lesson.pk * 1000 + song.pk)} key={`${i * 100 + j * 1000 + k}e`}
                                song={song} exe={props.exercise}
                                user_id={props.user_id} user_group_id={props.user_group_id}
                                setPkCallback={props.setPkCallback}
                            />
                        ))}
                    </SecondTreeItem>
                ))}
                </FirstTreeItem>
            ))}
        </SolfyTreeView>
    );
}

function SolfegeExerciseButton(props) {
    const exe = props.exe;
    return (
        <Button className="TreeViewBtn"
            id={String(props.course.pk * 100 + props.lesson.pk * 1000 + exe.pk)}
            disabled={props.user.as_student && !props.unlockedExercises.includes(exe.serial_number)}
            onClick={() => props.setPkCallback(exe.pk)}
            style={{ backgroundColor: exe.pk === props.exercise.pk ? 'white' : 'transparent', width: '100%' }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, width: '100%', }} >
                <p className="ellipsis-text" style={{ textTransform: 'none', fontSize: '13px', fontWeight: '550', }} >
                    {exe.title}
                </p>

                {props.solfegesPassStatus?.[exe.pk] ? (
                    <CheckCircleIcon style={{ fontSize: '18px', color: 'var(--blue-4)' }} />
                ) : (
                    <CheckCircleOutlineIcon
                        style={{ fontSize: '18px', color: 'var(--blue-3)' }}
                    />
                )}
            </div>
        </Button>
    );
}


function SongButton(props) {
    const song = props.song;
    const enabled = (song.unlocked_for_user_ids.length == 0 && song.unlocked_for_group_ids.length == 0) ||
        song.unlocked_for_user_ids.includes(props.user_id) || song.unlocked_for_group_ids.includes(props.user_group_id);

    return (
        <Button className="TreeViewBtn"
            onClick={() => props.setPkCallback(song.first_exercise.pk)}
            style={{backgroundColor: song.ids_set.includes(props.exe.pk) ? 'white' : 'transparent',}}
            disabled={!enabled}
            >
            <CustomTypography
                normalstyle={{ textTransform: 'none', fontWeight: '550', fontSize: '13px' }}
                smallstyle={{ textTransform: 'none', fontWeight: '450', fontSize: '11px' }}
                addclass="ellipsis-text"
                text={song.title}
                disabled={!enabled}
            />
        </Button>
    )
}


export default Sidebar;