import React, { useState, useEffect, useRef} from "react";
import { axiosInstance,  } from './axios_instance';
import Tabstext from './tabstext';
import { submitData, Animate, moveAudioCursor, createAudioObjects, getSupportedMimeType, playDelayedAudio } from './exercise_utils';
import { OutlinedButton, MediumArrowDropDownIcon, AudioMenuItem, } from './customized_components';
import { Menu, CircularProgress, Popover, Typography, Tooltip } from '@mui/material';
import { ReactComponent as PlayIcon } from '/static/icons/play.svg';
import { ReactComponent as PlayArrowIcon } from '/static/icons/play_arrow.svg';
import { ReactComponent as RecordIcon } from '/static/icons/record.svg';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';
import { ReactComponent as TrebleIcon } from '/static/icons/treble-clef.svg';
import { ReactComponent as BassIcon } from '/static/icons/bass-clef.svg';
import './styles/colors.scss';


function AudioMain(props) {
    const refDisplay = !props.feedbackDisplay;
    var autoplay = false;

    const playingObj = useRef(null);

    const [tempo, setTempo] = useState(null);
    const setTempoCallback = (val) => {
        setTempo(val);
    }
    const [audioGuideDisplay, setAudioGuideDisplay] = useState(null);
    const [audioRefDisplay, setAudioRefDisplay] = useState(null);
    const audioRefObjectsRef = useRef(null)
    const audioGuideObjectsRef = useRef(null)
    const recordedAudio = useRef(null)
    const recordedAudioAcc = useRef(null)
    const audioGuideKey = useRef(null)
    const [waitForFeedback, setWaitForFeedback] = useState(false);

    const handleAudioEnded = () => {
        PlayFlowCallback('stop');
    };
    const handleGuideEnded = () => {
        RecFlowCallback();
        props.canvasRef[2].scrollTop = 0;
    };

    useEffect(() => {
        setTempo(props.tempos[0])
    }, [props.tempos[0]]);

    useEffect(() => {
        if (props.exe && tempo && props.tempos.includes(tempo) && props.canvasRef) {
            axiosInstance.get('/api/exercise_audio_display/', {'params':{'pk':props.exe.pk, 'tempo':tempo, 'clef':props.clef}})
                .then((response) => {
                    if (response.data.audio_guide_display) {
                        setAudioGuideDisplay(response.data.audio_guide_display);
                        setAudioRefDisplay(response.data.audio_ref_display);
                    }
                })
                .catch(function (error) {
                    console.log('Error retrieving audio files:', error);
                });
        }
    }, [props.exe.pk,tempo,props.clef,props.canvasRef==null]);

    useEffect(() => {
        if (!props.recordingsOnly &&  props.exe && tempo && props.tempos.includes(tempo) && props.canvasRef) {
            const mimeType = getSupportedMimeType();
            axiosInstance.get('/api/exercise_audio/', { 'params': { 'pk': props.exe.pk, 'tempo': tempo, 'clef': props.clef, 'mimeType': mimeType } })
                .then((response) => {
                    if (response.data.audio_guide_files) {
                        const [tempRefs, tempGuides] = createAudioObjects(response.data.audio_guide_files,response.data.audio_ref_files,handleAudioEnded,handleGuideEnded)
                        audioRefObjectsRef.current = tempRefs;
                        audioGuideObjectsRef.current = tempGuides;
                        Animate(audioRefObjectsRef.current[0], tempo, props.canvasRef[1],props.canvasRef[2]);
                    }
                })
                .catch(function (error) {
                    console.log('Error retrieving audio files:', error);
                });
        }
    }, [props.exe.pk, tempo, props.clef, props.canvasRef==null]);

    useEffect(() => {
        if (!autoplay && Boolean(playingObj.current))
            PlayFlow('stop')
        if(refDisplay && audioRefObjectsRef.current)
            Animate(audioRefObjectsRef.current[0], tempo, props.canvasRef[1],props.canvasRef[2]);
    },[refDisplay,props.exe.pk,props.display]);

    const [audioState, setAudioState] = useState(0); // Play state: 0=idle, 1=playing, 2=pausing

    const PlayFlow = (cmd, audioObj, playtempo, recordingDelay) => {
        if (cmd=='play') {
            if (audioState==0 && audioObj) {
//                audioObj.currentTime = 0;
                playingObj.current=audioObj;
            }
            if (audioState != 1) {
                setAudioState(1);
                if (recordingDelay && recordingDelay < 0)
                    playDelayedAudio(playingObj, -recordingDelay);
                else
                    playingObj.current.play();
                if (playtempo) // only during initial play
                    Animate(playingObj.current, playtempo, props.canvasRef[1], props.canvasRef[2], recordingDelay);
            }
            else {
                setAudioState(2);
                playingObj.current.pause();
            }
        }
        else { // cmd=='stop'
            setAudioState(0);
            playingObj.current.pause();
            playingObj.current.currentTime = 0;
            props.canvasRef[2].scrollTo({top: 0});
       }
    };
    const PlayFlowCallback = (cmd, audio_id, playtempo, recordingDelay) => {
        let temp_obj = null;
        if (audio_id) {
            if (audio_id=='recorded-audio')
                temp_obj = recordedAudio.current;
            else if (audio_id=='recorded-audio-acc')
                temp_obj = recordedAudioAcc.current;
            else {
                const keys = Object.keys(audioRefDisplay);
                const index = keys.indexOf(audio_id);
                temp_obj = audioRefObjectsRef.current[index];
            }
            PlayFlow(cmd, temp_obj, playtempo, recordingDelay);
        }
        else
            PlayFlow(cmd); // stop
    };

    const [recState, setRecState] = useState(0);  // 0=idle, 1=recording
    const RecFlow = (guideObj) => {
        if (recState==0 && guideObj) {
//            setPlayingObj(guideObj)
            playingObj.current = guideObj;
            setRecState(1);
            Animate(guideObj,tempo,props.canvasRef[1],props.canvasRef[2]);
            const vfRenderer = props.canvasRef[0];
            vfRenderer.removeEventListener("click", moveAudioCursor, false);
            guideObj.currentTime=0;
            props.canvasRef[2].scrollTo({top: 0});
            guideObj.play();
            StartRecorder();
            autoplay = false;

        }
        else { // recState==1 or reached guide.onended
            setRecState(0);
            playingObj.current.pause();
            playingObj.current.currentTime=0;
            props.canvasRef[2].scrollTo({top: 0});
            const vfRenderer = props.canvasRef[0];
            StopRecorder();
        }
    };
    const RecFlowCallback = (audio_id) => {
        if (audio_id) {
            const keys = audioGuideDisplay.map(subArray => subArray[0]);
            const values = audioGuideDisplay.map(subArray => subArray[1]);
            const index = keys.indexOf(audio_id);
            audioGuideKey.current = typeof keys[index] === 'number'? values[index] : keys[index];
            RecFlow(audioGuideObjectsRef.current[index]);
        }
        else
            RecFlow(null); // stop
    };

    // recorder
    const recorderRef = useRef(null);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState(null); // State to store the audio URL for playback
    const { getUserMedia } = navigator.mediaDevices;
    const [open, setOpen] = useState(false);

    const activateRecorder = async () => {
        try {
            const stream = await getUserMedia.call(navigator.mediaDevices, {
                audio: {
                    deviceId: window.inputDeviceId,
                    echoCancellation: false,
                    //sampleRate: 48000,
                },
            });
            const mimeType = getSupportedMimeType();
            if (!mimeType) {
                console.error('No supported MIME type found for MediaRecorder.');
            }
            const recorder = new MediaRecorder(stream, { mimeType });
            recorder.ondataavailable = handleDataAvailable;
            recorder.start(1000);
            recorderRef.current = recorder;
        } catch (error) {
            console.error('Error with user input:', error);
            setOpen(true);
        }
    };

    const chunks = [];
    const handleDataAvailable = (e) => {
        if (e.data && e.data.size > 0) {
            chunks.push(e.data); // Add each chunk of data
        }
        if (recorderRef.current && recorderRef.current.state === 'inactive') {
            const blob = new Blob(chunks, { type: recorderRef.current.mimeType });
            chunks.length = 0; // Clear the chunks array after Blob is created
            submitData(blob, props.exe.pk, tempo, audioGuideKey.current, props.clef, props.inClass);
            setRecordedAudioUrl(URL.createObjectURL(blob)); // keep the recorded audio in a URL
            recorderRef.current = null;
            setWaitForFeedback(true);
        }
    };
    const StartRecorder = () => {
        activateRecorder();
    };
    const StopRecorder = () => {
        if (recorderRef.current != null) {
            recorderRef.current.stop();
            recorderRef.current.stream.getTracks().forEach(track => track.stop());
       }
    };
//    useEffect(() => {
//        console.log('Component mounted');
//    }, []);

    // feedback audio retreival
    useEffect(() => {
        if (props.feedbackData && props.feedbackDisplay) {
            let temp;
            if (recordedAudioUrl)
                temp = new Audio(recordedAudioUrl);
            else
                temp = new Audio(props.feedbackData.audio);
            temp.onended = handleAudioEnded;
            recordedAudio.current = temp;
            const data = props.feedbackData;
            setTempo(data.tempo);
            autoplay = Boolean(data.autoplay)
            if (!Object.keys(props.user.tips_status).includes('feedback') || props.user.tips_status['feedback']['show'] == true)
                autoplay = false;
            if (data.audio_acc != null) {
                let temp1 = new Audio(data.audio_acc)
                temp1.onended = handleAudioEnded;
                recordedAudioAcc.current = temp1;
            } else
                recordedAudioAcc.current = null;
            setWaitForFeedback(false);
        };
    }, [props.feedbackData]);

    useEffect(() => {
        if (props.feedbackData && props.canvasRef) {
            Animate(recordedAudio.current, tempo, props.canvasRef[1], props.canvasRef[2], props.feedbackData.recordingDelay);
            if (autoplay) {
                if (recordedAudioAcc.current != null) {
                    Animate(recordedAudioAcc.current, tempo, props.canvasRef[1], props.canvasRef[2]);
                    PlayFlow('play', recordedAudioAcc.current, tempo);
                }
                else
                    PlayFlow('play', recordedAudio.current, tempo);
            }
        };
    }, [props.feedbackData, props.canvasRef == 0]);

    if (audioRefDisplay==null || audioGuideDisplay==null ||tempo==null)
        return (null);

    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    const lang_align = props.lang == 'he' ? 'right' : 'left';

    return  (
       <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap:'10px' }}>
            <Popover
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={null}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 100 }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                disableEnforceFocus
            >
                <Typography className='popOver red' dir={lang_dir} >
                    {Tabstext.MicrophoneNotAllowedPopover[props.lang]}
                </Typography>
            </Popover>
            {refDisplay &&
                <PlayRefGroup audioRefDisplay={audioRefDisplay} exe={props.exe} lang={props.lang}
                    audioState={audioState} recState={recState}
                    PlayFlowCallback={PlayFlowCallback}
                    tempo={tempo}
                    canvasRef={props.canvasRef}
                />
            }
            {props.feedbackDisplay && props.feedbackData &&
                <PlayFdbkGroup exe={props.exe} lang={props.lang} audioState={audioState}
                    PlayFlowCallback={PlayFlowCallback}
                    optionalAcc={recordedAudioAcc.current!=null}
                    tempo={props.feedbackData.tempo}
                    recordingDelay={props.feedbackData.recordingDelay}
                />}
            {(audioState==1 || audioState==2) &&
                <OutlinedButton
                    variant="outlined"
                    onClick = {() => PlayFlow('stop',playingObj.current)}
                    style={{ marginLeft: '0' }}
                    >
                    {Tabstext.StopTab[props.lang]}
                </OutlinedButton>}
            {refDisplay &&
                <Tooltip
                    title=<div style={{ textAlign: lang_align }}>{Tabstext.RecordingTestRequired[props.lang]}</div>
                    placement="bottom"
                    disableHoverListener={Boolean(props.user.passed_recording_test)}
                    disableFocusListener={Boolean(props.user.passed_recording_test)}
                    disableTouchListener={Boolean(props.user.passed_recording_test)}
                >
                    <span>
                       <RecordGroup audioGuideDisplay={audioGuideDisplay} exe={props.exe} lang={props.lang}
                            audioState={audioState} recState={recState}
                            RecFlowCallback={RecFlowCallback}
                            tempo={tempo}
                            passedRectest={props.user.passed_recording_test}
                                />
                    </span>
                </Tooltip>
            }
            {refDisplay &&
                <Tooltip
                    title=<div style={{ textAlign: lang_align }}>{Tabstext.tempoGuide[props.lang]}</div>
                    placement="top"
                >
                    <span>
                        <TempoSelect tempos={props.tempos} lang={props.lang}
                            audioState={audioState || recState}
                            tempo={tempo} setTempoCallback = {setTempoCallback}
                            />
                    </span>
                </Tooltip>
            }
            {refDisplay && props.clefs &&
                <Tooltip
                    title=<div style={{ textAlign: lang_align }}>{Tabstext.ClefSelectTooltip[props.lang]}</div>
                    placement="top"
                >
                    <span>
                        <ClefSelect lang={props.lang}
                            audioState={audioState || recState}
                            clefs={props.clefs}
                            clef={props.clef} setClefCallback = {props.setClefCallback}
                                />
                    </span>
                </Tooltip>
            }
            {refDisplay && waitForFeedback &&
                <CircularProgress size={20} style={{ marginLeft: '20px' }} />
            }
        </div>
    )
}

function PlayRefGroup(props) {
    const audioRefDisplay = Object.entries(props.audioRefDisplay);
    const label =  props.audioState==1 ? Tabstext.PauseTab[props.lang] : Tabstext.PlayTab[props.lang]
    const thisclass =  props.audioState==1 ? 'fa fa-pause' : 'fa fa-play-circle-o'
//    const arrowTransparency = props.audioState==0 ? 1 : 0;
    const isPlayDisabled = props.recState==1 ? true : false

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (val) => {
        props.PlayFlowCallback('play',val,props.tempo);
        handleClose();
    };
    const handleButtonClick = (event) => {
        if (props.audioState==1 || props.audioState==2) // do pause or continue
            props.PlayFlowCallback('play');
        else // do play from start: open the menu
            handleClick(event);
    };

    useEffect(() => {
        if (props.refAudio)
            Animate(props.refAudio, props.tempo,props.canvasRef[1],props.canvasRef[2]);
    }, [props.refAudio, props.tempo]);

    return (
        <div >
            {audioRefDisplay.length==1 &&
                <OutlinedButton
                    startIcon={<PlayIcon />}
                    onClick={() => handleMenuClick(audioRefDisplay[0][0])}
                    disabled={isPlayDisabled}
                    >
                    {label}
                </OutlinedButton>
            }
            {audioRefDisplay.length>1 &&
              <>
                <OutlinedButton
                    startIcon={<PlayIcon />}
                    onClick={handleButtonClick}
                    disabled={isPlayDisabled}
                    endIcon={props.audioState==0? <MediumArrowDropDownIcon /> : null}
                    >
                    {label}
                </OutlinedButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    >
                    {audioRefDisplay.map(([r,text],index) => (
                        <AudioMenuItem
                            key = {r}
                            onClick={() => handleMenuClick(r)}
                            isLastItem={index === audioRefDisplay.length - 1}
                            >
                            <PlayArrowIcon />
                            { Tabstext[text][props.lang] }
                        </AudioMenuItem>
                    ))}
                </Menu>
              </>
            }
        </div>
    )
}

function PlayFdbkGroup(props) {
    const [withAcc, setWithAcc] = useState(false);
    const label =  props.audioState==1 ?Tabstext.PauseTab[props.lang] : Tabstext.PlayTab[props.lang]
    const thisclass =  props.audioState==1 ? 'fa fa-pause' : 'fa fa-play-circle-o'
    const arrowTransparency = props.audioState==0 ? 1 : 0;
    const isPlayDisabled = false; // props.audioState==0 ? false : true

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (val) => {
        if (val == 'recorded-audio') // play from local
            props.PlayFlowCallback('play', val, props.tempo, props.recordingDelay);
        else // download with acc, delay already compensated for
            props.PlayFlowCallback('play', val, props.tempo);
        handleClose();
    };
    const handleButtonClick = (event) => {
        if (props.audioState==1 || props.audioState==2) // do pause or continue
            props.PlayFlowCallback('play');
        else // do play from start: open the menu
            handleClick(event);
    };

    return (
        <div >
            {!props.optionalAcc &&
                <OutlinedButton
                    startIcon={<PlayIcon />}
                    onClick={() => handleMenuClick('recorded-audio')}
                    disabled={isPlayDisabled}
                    >
                    {label}
                </OutlinedButton>
            }
            { props.optionalAcc &&
              <>
                <OutlinedButton
                    startIcon={<PlayIcon />}
                    onClick={handleButtonClick}
                    disabled = {isPlayDisabled}
                    endIcon={<MediumArrowDropDownIcon style={{ opacity: arrowTransparency }} />}
                    >
                    <i className={thisclass}></i>{label}
                </OutlinedButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    >
                    <AudioMenuItem
                        onClick={() => handleMenuClick('recorded-audio')}
                        isLastItem={false}
                        >
                            <PlayArrowIcon />
                            { Tabstext.PlayWithoutAcc[props.lang] }
                    </AudioMenuItem>
                    <AudioMenuItem
                        onClick={() => handleMenuClick('recorded-audio-acc')}
                        isLastItem={true}
                        >
                            <PlayArrowIcon />
                            { Tabstext.PlayWithAcc[props.lang] }
                    </AudioMenuItem>
                </Menu>
              </>
            }
        </div>

    )
}

function RecordGroup(props) {
    //const audioGuideDisplay = Object.entries(props.audioGuideDisplay);
    const audioGuideDisplay = props.audioGuideDisplay;
    const label = props.recState == 1 ? Tabstext.StopTab[props.lang] : Tabstext.RecordTab[props.lang];
    const thisclass = props.recState == 1 ? 'fa fa-stop' : 'fa fa-circle';
    const isRecDisabled = (!props.passedRectest || props.audioState == 1 || props.audioState == 2) ? true : false;
    const arrowTransparency = props.recState == 1 ? 0 : 1;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (val) => {
        props.RecFlowCallback(val);
        handleClose();
    };
    const handleButtonClick = (event) => {
        if (props.recState == 1) // do stop
            props.RecFlowCallback();
        else // do rec from start: open the menu
            handleClick(event);
    };

    return (
        <div >
            <OutlinedButton
                startIcon={<RecordIcon />}
                onClick={handleButtonClick}
                disabled={isRecDisabled}
                style={{ marginLeft: '0' }}
                endIcon={<MediumArrowDropDownIcon style={{ opacity: arrowTransparency }} />}
            >
                <i className={thisclass}></i>{label}
            </OutlinedButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {audioGuideDisplay.map(([g, text], index) => (
                    <AudioMenuItem
                        key={g}
                        onClick={() => handleMenuClick(g)}
                        isLastItem={index === audioGuideDisplay.length - 1}
                    >
                        <MicIcon />
                        {text.includes('tab') ? Tabstext[text][props.lang] : [Tabstext['named_guide_tab'][props.lang] + text]}
                    </AudioMenuItem>
                ))}
            </Menu>
        </div>
    )
}

function TempoSelect(props) {
    const tempos = props.tempos;
    const isDisabled = props.audioState === 0 ? false : true;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectTempo = (val) => {
        if (val !== props.tempo) {
            props.setTempoCallback(val);
        }
        handleCloseMenu();
    };

    return (
        <div >
            <OutlinedButton
                id="tempo-select-button"
                variant="outlined"
                disabled={isDisabled}
                onClick={handleOpenMenu}
                endIcon={<MediumArrowDropDownIcon />}
                style={{ marginLeft: '0' }}
                >
                {props.tempo} BPM
            </OutlinedButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                >
                {tempos.map((t, index) => (
                    <AudioMenuItem
                        value={t}
                        onClick={() => handleSelectTempo(t)}
                        key={index}
                        isLastItem={index === tempos.length - 1}
                    >
                        {t}
                    </AudioMenuItem>
                ))}
            </Menu>
        </div>
    );
}


function ClefSelect(props) {
    const clefs = props.clefs;
    const isDisabled = props.audioState === 0 ? false : true;
    const [anchorEl, setAnchorEl] = useState(null);
    const [disableTooltip, setDisableTooltip] = useState(false);  

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDisableTooltip(true); 
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setDisableTooltip(false);
    };

    const handleSelectClef = (val) => {
        if (val !== props.clef) {
            props.setClefCallback(val);
        }
        handleCloseMenu();
    };
    const lang_align = props.lang == 'he' ? 'right' : 'left';

    return (
        <div >
            <OutlinedButton
                id="clef-select-button"
                variant="outlined"
                disabled={isDisabled}
                onClick={handleOpenMenu}
                endIcon={<MediumArrowDropDownIcon />}
                style={{ marginLeft: '0', padding:'4px' }}
            >
                {props.clef == 'treble' ? <TrebleIcon className='blue-5' /> : <BassIcon className='blue-5' />}
            </OutlinedButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {clefs.map((t, index) => (
                    <AudioMenuItem
                        value={t}
                        onClick={() => handleSelectClef(t)}
                        key={index}
                        isLastItem={index === clefs.length - 1}
                    >
                        {t == 'treble' ? <TrebleIcon className='blue-5' /> : <BassIcon className='blue-5' />}
                    </AudioMenuItem>
                ))}
                </Menu>
        </div>
    );
}


export default AudioMain;

