import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import { FormTitle, SolfyTextField, ContainedButton, SmallTitle } from './customized_components';
import { Paper, FormControlLabel, Checkbox, Radio } from '@mui/material';

function UserProfile(props) {
    const lang = props.lang;
    const user = props.user;
    const [groupName, setGroupName] = useState(user.group_name);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isResetTipsChecked, setIsResetTipsChecked] = useState(false);
    const [formData, setFormData] = useState(null);
    const [submitEnable, setSubmitEnable] = useState(false);
    const [validGroup, setValidGroup] = useState(true);

    useEffect(() => {
        setFormData({
            first_name: user.first_name, last_name: user.last_name,
            groupid: user.group_id,
            school: user.school ? user.school : '', location: user.location ? user.location : '',
            tips_reset: false,
        });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        if (name == 'groupid')
            setGroupNameFromServer(value);
        else
            setSubmitEnable(true);
    };

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        if (e.target.checked) { }
    }
    const handleResetTips = (e) => {
        setIsResetTipsChecked(e.target.checked);
        if (e.target.checked) {
            setFormData((prevFormData) => ({ ...prevFormData, ['tips_reset']: true }));
            setSubmitEnable(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.post('/api/user_settings/', {
            first_name: formData.first_name, last_name: formData.last_name, groupid: formData.groupid,
            school: formData.school, location: formData.location,
            tips_reset: formData.tips_reset,
        }).then();

        props.setUserCallback({
            first_name: formData.first_name, last_name: formData.last_name, school: formData.school, location: formData.location,
            tips_reset: formData.tips_reset,
            }); 

        setSubmitEnable(false);
        props.resetViewCallback(true);
    };

    const handleCancel = (e) => {
        setFormData({
            first_name: user.first_name, last_name: user.last_name,
            groupid: user.group_id,
            school: formData.school, location: formData.location,
        });
        props.resetViewCallback(false);
    };

    const setGroupNameFromServer = (id) => {
        if (props.allGroupIds.includes(parseInt(id))) {
            axiosInstance.post('/api/get_group_name/', { group_id: id })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.group_name) {
                            setGroupName(response.data.group_name);
                            setSubmitEnable(true);
                            setValidGroup(true);
                        }
                        else {
                            setGroupName(Tabstext.NoSuchGroupTab[props.lang]);
                            setSubmitEnable(false);
                            setValidGroup(false);
                        }
                    }
                });
        }
        else {
            setGroupName(Tabstext.NoSuchGroupTab[props.lang]);
            setSubmitEnable(false);
            setValidGroup(false);
        }
    }

    const groupNameColor = validGroup ? 'inherit' : 'red';
    const lang_dir = lang=='he' ? 'rtl' : 'ltr';
    const lang_align = props.lang == 'he' ? 'right' : 'left';

    if (user==null || formData==null)
        return null;

    return (
        <div dir={lang_dir}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column', 
                textAlign: lang_align,
            }}
            >
            <Paper className='longPaper' dir={lang_dir} >
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', gap:'32px', marginBottom: '8px', }}>
                        <SolfyTextField
                            label={Tabstext.FirstNameTab[lang]}
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            dir={lang_dir}
                            fullWidth
                        />
                        <SolfyTextField
                            label={Tabstext.LastNameTab[lang]}
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            dir={lang_dir}
                            fullWidth
                        />
                    </div>
                    <div style={{ marginBottom: '2px' }}>
                        <SolfyTextField
                            label={Tabstext.YourGroupTab[props.lang]}
                            name="groupid"
                            value={formData.groupid}
                            dir={lang_dir}
                            fullWidth
                            onChange={handleChange}
                        />
                    </div>
                    <SmallTitle style={{ fontSize: '12px', marginBottom: '10px', color: groupNameColor }} >
                        {groupName}
                    </SmallTitle>
                    <div>
                    {user.group_name == 'Independent' && !user.teacher &&
                        <FormControlLabel
                            control={<Checkbox size='small' checked={isCheckboxChecked} onChange={handleCheckboxChange} />}
                            label={Tabstext.IwantToRegisterAsTeacher[props.lang]}
                        />
                    }
                    {(isCheckboxChecked || user.teacher) && 
                        <TeacherSchoolDetails
                            formData={formData}
                            handleChange={handleChange}
                            lang={props.lang}
                        />
                        }
                    </div>
                    <FormControlLabel
                        control={<Checkbox size='small' checked={isResetTipsChecked} onChange={handleResetTips} />}
                        label={Tabstext.ResetTips[props.lang]}
                    />
                   <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                        <ContainedButton
                            type="submit"
                            disabled={!submitEnable || !validGroup}
                        >
                            {Tabstext.SaveTab[lang]}
                        </ContainedButton>
                        <ContainedButton
                            onClick={() => handleCancel()}
                            >
                            {Tabstext.CancelTab[lang]}
                        </ContainedButton>
                    </div>
                </form>
            </Paper>
        </div>
    );
}

function TeacherSchoolDetails(props) {
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    fullWidth
                    type='text'
                    name='school'
                    value={props.formData.school}
                    label={Tabstext.YourSchoolTab[props.lang]}
                    dir={lang_dir}
                    required
                    onChange={props.handleChange}
                />
            </div>
            <div style={{ marginBottom: '8px' }}>
                <SolfyTextField
                    fullWidth
                    type='text'
                    name='location'
                    value={props.formData.location}
                    label={Tabstext.YourLocationTab[props.lang]}
                    dir={lang_dir}
                    required
                    onChange={props.handleChange}
                />
            </div>
        </>
    );
}
export default UserProfile;