import React from 'react'
import Tabstext from './tabstext';
import { HeadTableCell, BodyTableCell, CellLink, } from './customized_components';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';

function SongsProgressTable(props) {
    const tableHeight = props.windowHeight*0.75
    const theoryInfo=props.theoryInfo;

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
        <TableContainer component={Paper}
            style={{ boxShadow: 'none', maxHeight: `${tableHeight}px`, overflowY: 'auto', display:props.display}}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow >
                        <HeadTableCell style={{ borderRadius:'6px 0 0 6px'}} >
                            {Tabstext.ExerciseTab[props.lang]}
                        </HeadTableCell>
                        <HeadTableCell >
                            {Tabstext.RecordingsTab[props.lang]}
                        </HeadTableCell>
                        <HeadTableCell style={{ borderRadius:'0 6px 6px 0'}} >
                            {Tabstext.ScoreTab[props.lang]}
                        </HeadTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {theoryInfo.map((exe, index) => (
                        <TableRow key={index}>
                            <BodyTableCell style={{ }}>
                                {props.teacherReview &&
                                <CellLink
                                    onClick={() => props.handleExeSelect(exe,'theory')}
                                    >
                                    {exe.title}
                                </CellLink>
                                }
                                {! props.teacherReview && exe.title}
                            </BodyTableCell>
                            <BodyTableCell >{exe.num_recs}</BodyTableCell>
                            <BodyTableCell >{exe.performance_score}</BodyTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SongsProgressTable;

