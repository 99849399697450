import React, { useState, useEffect, useRef } from "react";
import { axiosInstance,  } from './axios_instance';
import PracticeRecordingsSelect from './practice_recordings_select';
import ScoreSyricsSelect from './score_lyrics_select';
import ClassHomeSelect from './class_home_select';
import ToleranceSetting from './tolerance_setting';
import RenderLyricsHtml from './render_lyrics';
import RunsHistory from './runs_history';
import ScorePage from './score_page';
import ZoomSelection from './zoom_selection';
import FullScreenButton from './fullscreen';
import Tabstext from './tabstext';
import {render_lyrics_docx, PutSetFeedbackCallback, } from './exercise_utils';
import { ExerciseContainer, ForwardArrowButton } from './customized_components';

function ExercisePage(props) {
    const [inClass, setInClass] = useState(false);
    const [clef, setClef] = useState(null);
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    //const [clefs, setClefs] = useState(['treble']); // is this needed

    const context = props.exerciseData.context;
    const exercise = props.exerciseData.exe;
    const nextExe = props.exerciseData.next;
    const prevExe = props.exerciseData.prev;

    const runs_dict = props.exerciseData.runs_dict;
    const clefs = exercise.score_bass ? ['treble', 'bass'] : null;

    useEffect(() => {
        setFeedbackDisplay(false);
        setExerciseView('score');
        setFeedbackData(null);
        //setClefs(exercise.score_bass ? ['treble', 'bass'] : null);
        setClef(context.clef);
        setMusicScore(context.clef == 'bass' ? exercise.score_bass : exercise.score);
    }, [exercise.pk, props.user.as_student]);

    const [exerciseView, setExerciseView] = useState('score');
    const setExerciseViewCallback = (val) => {
        setExerciseView(val);
    };
    const setClefCallback = (val) => {
        setClef(val);
        setMusicScore(val == 'bass' ? exercise.score_bass : exercise.score);
    };

    // practice / feedback / runs_table  display state machine
    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const setFeedbackDisplayCallback = (val, data, newRecording) => {
        //console.trace('Callback invoked');
        setFeedbackDisplay(val);
        if (val) {
            if (data || feedbackData) {
                if(data) {
                    setMusicScore(data.score);
                    setFeedbackData(data);
                    if (newRecording && data.message_color == 'green')
                        props.reloadExerciseData();
                } else {
                    setMusicScore(feedbackData.score);
                }
                setExerciseView('score');
            }
            else
                setExerciseView('runs_table');
        }
        else {
            setExerciseView('score');
            setMusicScore(clef == 'bass' ? exercise.score_bass : exercise.score);
        }
    };
    PutSetFeedbackCallback(setFeedbackDisplayCallback);     // invoked from exercise_utils

    const setMusicScoreCallback = (data) => {
        setMusicScore(data.score);
        //setFeedbackData(data); // I tried to keep the reanalyzed feedback to stick but it caused a slider problem if invoking the same run again from runs_history
    }

    const docxRef = useRef(null);
    useEffect(() => {
        if (context && Object.keys(context).length > 0 && !feedbackDisplay) {
            if (exerciseView=='score') {
                setMusicScore(clef == 'bass' ? exercise.score_bass : exercise.score);
            }
            else if (exerciseView=='lyrics' && context.lyrics_file_url && context.lyrics_file_url.includes('docx')) {
                render_lyrics_docx(context.lyrics_file_url,docxRef.current);
            }
        }
    }, [exercise,exerciseView]);

    useEffect(() => {
        if (props.user.as_student)
            setInClass(false)
    }, [props.user.as_student,]);

    const runsTableWidth = props.windowSize[0]-500 ;
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    const lyric_dir = exercise.lyric=='Hebrew' ? 'rtl' : 'ltr';

    return (
        <ExerciseContainer  >
            <div >
                {exerciseView!=='lyrics' &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PracticeRecordingsSelect context={context} lang={props.lang}
                                feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                            />
                            {exerciseView == 'score' && feedbackDisplay && feedbackData &&
                                <AllRunsSelect lang={props.lang}
                                    exerciseView={exerciseView} setExerciseViewCallback={setExerciseViewCallback}
                                />
                            }
                        </div>
                        {exerciseView == 'score' && !inClass && !feedbackDisplay &&
                            <ToleranceSetting
                                {...props}
                                feedbackDisplay={feedbackDisplay}
                                tooltipText={Tabstext.SettingsToleranceTooltip[props.lang]}
                                pitchTolerance={props.user.pitch_tolerance}
                            />
                        }
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            {Boolean(context.isteacher) &&  !props.user.as_student && !feedbackDisplay &&
                                <ClassHomeSelect
                                    context={props.context} lang={props.lang}
                                    inClass={props.inClass} inClassChangeCallback={props.inClassChangeCallback}
                                    />
                            }
                            <ZoomSelection lang={props.lang} scale={props.scale} setScaleCallback={props.setScaleCallback} />
                            <FullScreenButton lang={props.lang} />
                        </div>

                    </div>
                }
                {exerciseView=='lyrics' &&
                <>
                    <div style={{ display: 'flex', justifyContent: 'right'}}>
                        <ScoreSyricsSelect
                            context={context}
                            lang={props.lang}
                            exerciseView={exerciseView}
                            setExerciseViewCallback={setExerciseViewCallback}
                        />
                    </div>
                    {context.lyrics_file_url.includes('html') &&
                        <RenderLyricsHtml lyrics_file_url = {context.lyrics_file_url} dir={lyric_dir} />
                    }
                    <div id="docx-container" ref={docxRef} dir={lyric_dir}></div>
                </>
                }
            </div>
            {exerciseView == 'runs_table' &&
                <RunsHistory 
                    {...props}
                    exe={exercise}
                    runs_dict={runs_dict}
                    setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    setExerciseViewCallback={setExerciseViewCallback}
                    runsTableWidth={runsTableWidth}
                />
            }
            {exerciseView == 'score' &&
                <ScorePage context={context} lang={props.lang}
                    {...props}
                    exe={exercise} nextExe={nextExe} prevExe={prevExe}
                    nextEnabled={context.next_enabled || (context.category === 'solfege' && !props.user.as_student)}
                    prevEnabled={context.prev_enabled}
                    musicScore={musicScore}
                    inExercisePage={true} feedbackData={feedbackData}
                    inClass={inClass}
                    exerciseView={exerciseView} setExerciseViewCallback={setExerciseViewCallback}
                    feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    setMusicScoreCallback={setMusicScoreCallback}
                    clefs={clefs}
                    clef={clef} setClefCallback={setClefCallback}
                />
            }
        </ExerciseContainer>
    )
}

function AllRunsSelect(props) {
    const lang = props.lang;
    const handleButtonClick = () => {
        props.setExerciseViewCallback('runs_table');
    };
    return (
        <ForwardArrowButton onClick={handleButtonClick} >
            {Tabstext.all_recordings_tab[lang]}
        </ForwardArrowButton>
    )
}


export default ExercisePage;
