import React, { useRef, useEffect } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import './styles/extras.css';
import { SolfyToggleButton, } from './customized_components'
import Tabstext from './tabstext';
import { Tooltip, } from '@mui/material';
import { ReactComponent as ScoreIcon } from '/static/icons/score.svg';
import { ReactComponent as LyricsIcon } from '/static/icons/lyrics.svg';

function ScoreSyricsSelect(props) {
    const context = props.context;
    const handleDisplaySelect = (event, val) => {
        if (val !== null && val !== props.exerciseView)
            props.setExerciseViewCallback(val);
    };
    if (context.lyrics_file_url)
        return (
            <Tooltip title={Tabstext.LyricsScoreToggleTooltip[props.lang] } placement="top" >
                <ToggleButtonGroup
                    value={props.exerciseView}
                    exclusive
                    onChange={handleDisplaySelect}
                >
                    <SolfyToggleButton
                        value={'score'} >
                        <ScoreIcon />
                    </SolfyToggleButton>
                    <SolfyToggleButton
                        value={'lyrics'} >
                        <LyricsIcon />
                    </SolfyToggleButton>
                </ToggleButtonGroup>
            </Tooltip>
        );
    else
        return null;

}


export default ScoreSyricsSelect
