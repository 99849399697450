import React from 'react';
import { useState, useEffect, } from "react";
import { Typography, Switch, Tooltip, Radio } from '@mui/material';
import './styles/extras.css';
import './styles/colors.scss';
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';

function AsStudentSelect(props) {

    const [selected, setSelected] = useState(false);
    useEffect(() => {
        setSelected(props.user.as_student);
    }, [props.user.as_student]);

    const handleAsStudentSelect = () => {
        axiosInstance.post('/api/user_settings/', { as_student: !selected }).then();
        props.setUserCallback({ 'as_student': !selected });
        setSelected(!selected); // invert
    };
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    const teacherColor = selected ? 'rgba(0, 0, 0, 0.5)' : 'var(--blue-5)';
    const studentColor = selected ? 'var(--blue-5)' : 'rgba(0, 0, 0, 0.5)';


    return (
        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center',  }}>
            <Tooltip title={Tabstext.AsTeacherTooltip[props.lang]} placement="top">
                <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}>
                    <Typography
                        variant="h6"
                        style={{ color: teacherColor, lineHeight: 3 }}
                    >
                        {Tabstext.AsTeacherTab[props.lang]}
                    </Typography>
                    <Radio
                        id="teacher-radio"
                        checked={!selected}
                        onChange={handleAsStudentSelect}
                        value="teacher"
                        sx={{ padding: '4px', '& .MuiSvgIcon-root': { fontSize: '16px' } }}
                    />
                </label>
            </Tooltip>
            <Tooltip title={Tabstext.AsStudentTooltip[props.lang]} placement="top">
                <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Radio
                        id="student-radio"
                        checked={selected}
                        onChange={handleAsStudentSelect}
                        value="student"
                        sx={{ padding: '4px', '& .MuiSvgIcon-root': { fontSize: '16px' } }}
                    />
                    <Typography
                        variant="h6"
                        style={{ color: studentColor, lineHeight: 3 }}
                    >
                        {Tabstext.AsStudentTab[props.lang]}
                    </Typography>
                </label>
            </Tooltip>
        </div>
    );
}

export default AsStudentSelect;

