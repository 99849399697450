import React, { useState, useEffect, useRef} from "react";
import { axiosInstance, } from './axios_instance';
import { Typography, Tooltip, CircularProgress } from '@mui/material';
import Tabstext from './tabstext';
import FeedbackMessage from './feedback_message';
import { submitData, Animate, moveAudioCursor, createAudioObjects, getSupportedMimeType, playDelayedAudio, createAudioMix, createAudio } from './exercise_utils';
//import { createAudioMix, } from './tone_utils';
import { OutlinedButton, } from './customized_components';
import { ReactComponent as PlayIcon } from '/static/icons/play.svg';
import { ReactComponent as RecordIcon } from '/static/icons/record.svg';
//import { normalizeAudio } from './normalize_audio'

function RectestAudio(props) {
    const refDisplay = !props.feedbackDisplay;
    var autoplay = false;
    const playingObj = useRef(null);

    const tempo = props.tempo;
    const [audioGuideDisplay, setAudioGuideDisplay] = useState(null);
    const [audioRefDisplay, setAudioRefDisplay] = useState(null);
    const audioRefObjectsRef = useRef(null);
    const audioGuideObjectsRef = useRef(null);
    const recordedAudio = useRef(null);
    const audioGuideKey = useRef(null);
    //const audioMix = useRef(null);
    const [waitForFeedback, setWaitForFeedback] = useState(false);


    const handleAudioEnded = () => {
        PlayFlowCallback('stop');
        props.canvasRef[2].scrollTop = 0;
    };
    const handleFeedbackAudioEnded = () => {
        PlayFlowCallback('stop');
        props.canvasRef[2].scrollTop = 0;
    };
    const handleGuideEnded = () => {
        RecFlowCallback();
        props.canvasRef[2].scrollTop = 0;
    };

    useEffect(() => {
        if (props.exe && tempo && props.canvasRef) {
            axiosInstance.get('/api/exercise_audio_display/', {'params':{'pk':props.exe.pk, 'tempo':tempo}})
                .then((response) => {
                    if (response.data.audio_guide_display) {
                        setAudioGuideDisplay(response.data.audio_guide_display);
                        setAudioRefDisplay(response.data.audio_ref_display);
                    }
                })
                .catch(function (error) {
                    console.log('Error retrieving audio files:', error);
                });
        }
    }, [props.canvasRef!=null]);
    useEffect(() => {
        if (props.exe && tempo && props.canvasRef) {
            const mimeType = getSupportedMimeType();
            axiosInstance.get('/api/exercise_audio/', { 'params': { 'pk': props.exe.pk, 'tempo': tempo, 'mimeType': mimeType } })
                .then((response) => {
                    if (response.data.audio_guide_files) {
                        const [tempRefs, tempGuides] = createAudioObjects(response.data.audio_guide_files,response.data.audio_ref_files,handleAudioEnded,handleGuideEnded)
                        audioRefObjectsRef.current = tempRefs;
                        audioGuideObjectsRef.current = tempGuides;
                        //audioMix.current = createAudio(tempRefs[0]);
                        Animate(audioRefObjectsRef.current[0], tempo, props.canvasRef[1],props.canvasRef[2]);
                    }
                })
                .catch(function (error) {
                    console.log('Error retrieving audio files:', error);
                });
        }
    }, [props.canvasRef!=null]);

    useEffect(() => {
        if (!autoplay && Boolean(playingObj.current))
            PlayFlow('stop')
        if(refDisplay && audioRefObjectsRef.current)
            Animate(audioRefObjectsRef.current[0], tempo, props.canvasRef[1],props.canvasRef[2]);
        if(props.feedbackDisplay && recordedAudio)
            Animate(recordedAudio, tempo, props.canvasRef[1],props.canvasRef[2]);
    }, [props.feedbackDisplay]);

    const [audioState, setAudioState] = useState(0); // Play state: 0=idle, 1=playing, 2=pausing

    const PlayFlow = (cmd, audioObj, playtempo, recordingDelay) => {
        if (cmd=='play') {
            if (audioState==0 && audioObj) {
                playingObj.current=audioObj;
            }
            if (audioState != 1) {
                setAudioState(1);
                if (recordingDelay && recordingDelay < 0)
                    playDelayedAudio(playingObj, -recordingDelay);
                else
                    playingObj.current.play();
                if(playtempo) // only during initial play
                    Animate(playingObj.current, playtempo, props.canvasRef[1], props.canvasRef[2], recordingDelay);
            }
            else {
                setAudioState(2);
                playingObj.current.pause();
            }
        }
        else { // cmd=='stop'
            setAudioState(0);
            playingObj.current.pause();
            playingObj.current.currentTime = 0;
        }
    };
    const PlayFlowCallback = (cmd, audio_id, playtempo, recordingDelay) => {
        let temp_obj = null;
        if (audio_id) {
            if (audio_id=='recorded-audio')
                temp_obj = recordedAudio.current;
            else {
                temp_obj = audioRefObjectsRef.current[0];
                //temp_obj = audioMix.current;
            }
            PlayFlow(cmd, temp_obj, playtempo, recordingDelay);
        }
        else
            PlayFlow(cmd); // stop
    };

    const [recState, setRecState] = useState(0);  // 0=idle, 1=recording
    const RecFlow = (guideObj) => {
        if (recState==0 && guideObj) {
            // use here a callback based method to block recording until browser allows microphone
            StartRecorder(guideObj);
        }
        else { // recState==1 or reached guide.onended
            setRecState(0);
            playingObj.current.pause();
            playingObj.current.currentTime=0;
            const vfRenderer = props.canvasRef[0];
            StopRecorder();
        }
    };
    const RecFlowCallback = (audio_id) => {
        if (audio_id)
            RecFlow(audioGuideObjectsRef.current[0]);
        else
            RecFlow(null); // stop
    };

    // recorder
    const recorderRef = useRef(null);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState(null); // State to store the audio URL for playback

    const StartRecorder = (guideObj) => {
        activateRecorder((stream) => {
            const mimeType = getSupportedMimeType();
            if (!mimeType) {
                console.error('No supported MIME type found for MediaRecorder.');
            }
            const recorder = new MediaRecorder(stream, { mimeType });
            recorder.ondataavailable = handleDataAvailable;
            recorder.start(1000);
            recorderRef.current = recorder;
            playingObj.current = guideObj;
            setRecState(1);
            Animate(guideObj,tempo,props.canvasRef[1],props.canvasRef[2]);
            const vfRenderer = props.canvasRef[0];
            vfRenderer.removeEventListener("click", moveAudioCursor, false);
            guideObj.play();
            autoplay = false;
        });
    };

    const activateRecorder = (callback) => {
        navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: window.inputDeviceId,
                echoCancellation: false,
                //sampleRate: 48000,
            }
        })
        .then(stream => {
            callback(stream); // Permission granted, execute the callback with the stream
        })
        .catch(error => {
            console.error('Error with user input:', error); // Permission denied or error occurred
            setOpen(true);
        });
    };

    const chunks = [];
    const handleDataAvailable = async (e) => {
        if (e.data && e.data.size > 0) {
            chunks.push(e.data); // Add each chunk of data
        }
        if (recorderRef.current && recorderRef.current.state === 'inactive') {
            const blob = new Blob(chunks, { type: recorderRef.current.mimeType });
            chunks.length = 0; // Clear the chunks array // or chunks.current=[]
            submitData(blob, props.exe.pk, tempo, audioGuideKey.current, 'treble', props.inClass);
            //const normalizedAudioUrl = await normalizeAudio(blob);
            //setRecordedAudioUrl(normalizedAudioUrl);
            setRecordedAudioUrl(URL.createObjectURL(blob)); // keep the recorded audio in a URL
            recorderRef.current = null;
            setWaitForFeedback(true);
        }
    };

    const StopRecorder = () => {
        if (recorderRef.current != null) {
            recorderRef.current.stop();
            recorderRef.current.stream.getTracks().forEach(track => track.stop());
        }
    };

    useEffect(() => {
        if (props.feedbackData && props.feedbackDisplay && props.canvasRef) {
            //let temp = new Audio(props.feedbackData.audio);
            let temp = new Audio(recordedAudioUrl);
            temp.onended = handleFeedbackAudioEnded;
            recordedAudio.current = temp;
            const data = props.feedbackData;
            if (recordedAudio.current) {
                //const audioMixObj = createAudioMix(recordedAudio.current, audioGuideObjectsRef.current[0], 1, data.recordingDelay);
                //Animate(audioMixObj, data.tempo, props.canvasRef[1], props.canvasRef[2], data.recordingDelay);
                //PlayFlow('play', audioMixObj, data.tempo);
                Animate(recordedAudio.current, data.tempo, props.canvasRef[1], props.canvasRef[2], data.recordingDelay);
                autoplay = Boolean(data.autoplay)
                if (autoplay) {
                    PlayFlow('play', recordedAudio.current, data.tempo, data.recordingDelay);
                }
            }
            setWaitForFeedback(false);
        };
    }, [props.feedbackData]);


    const [open, setOpen] = useState(false);
    const [openHelp, setOpenHelp] = useState(true);
    const anchorRef1 = useRef(null);
    const anchorRef2 = useRef(null);
    const closeHelpCallback = () => {
        setOpenHelp(false);
    }

    const handleFeedbackButton = (val) => {
        props.setFeedbackDisplayCallback(false);
        if (val == 1) {
            PlayFlow('stop');
            axiosInstance.post('/api/user_settings/', { passed_recording_test: 1 }).then();
            props.setUserCallback({ 'passed_recording_test': 1 });
            if (props.onExit)
                props.onExit();
        }
    }

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    const lang_align = props.lang=='he' ? 'right' : 'left';

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', padding: '12px' }}>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap:'10px' }}>
                {refDisplay &&
                    <PlayRefGroup audioRefDisplay={audioRefDisplay} exe={props.exe} lang={props.lang}
                        audioState={audioState} recState={recState}
                        PlayFlowCallback={PlayFlowCallback}
                        tempo={tempo}
                        canvasRef={props.canvasRef}
                        lang_align={lang_align}
                    />
                }
                {props.feedbackDisplay &&
                    <PlayFdbkGroup exe={props.exe} lang={props.lang} audioState={audioState}
                        PlayFlowCallback={PlayFlowCallback}
                        tempo={props.feedbackData.tempo}
                        recordingDelay={props.feedbackData.recordingDelay} 
                        handlePlaybackEndCallback={props.handlePlaybackEndCallback}
                    />}
                {(audioState==1 || audioState==2) &&
                    <OutlinedButton onClick = {() => PlayFlow('stop',playingObj.current)} >
                        {Tabstext.StopTab[props.lang]}
                    </OutlinedButton>}
                {refDisplay &&
                    <div ref={anchorRef2} >
                        <RecordGroup audioGuideDisplay={audioGuideDisplay} exe={props.exe} lang={props.lang}
                            audioState={audioState} recState={recState}
                            RecFlowCallback={RecFlowCallback}
                            tempo={tempo}
                            lang_align={lang_align}
                            />
                    </div>
                }
                {refDisplay && waitForFeedback &&
                    <CircularProgress size={20} style={{ marginLeft: '20px' }} />
                }
            </div>
            {props.feedbackData && props.feedbackData.feedback_message &&
                <FeedbackMessage
                    lang={props.lang}
                    feedbackData={props.feedbackData}
                    inClass={props.inClass} recTest={true}
                    display={props.feedbackDisplay}
                    handleFeedbackButton={handleFeedbackButton}
                />
            }
        </div>
    )
}

function PlayRefGroup(props) {
    const audioRefDisplay = props.audioRefDisplay ? Object.entries(props.audioRefDisplay)[0][0] : null;
    const label =  props.audioState==1 ? Tabstext.PauseTab[props.lang] : Tabstext.Play1Tab[props.lang]
    const isPlayDisabled = props.recState==1 ? true : false

    const handleMenuClick = (val) => {
        props.PlayFlowCallback('play',val,props.tempo);
    };

    useEffect(() => {
        if (props.refAudio)
            Animate(props.refAudio, props.tempo,props.canvasRef[1],props.canvasRef[2]);
    }, [props.refAudio, props.tempo]);

    return (
        <Tooltip
            title=<div style={{ textAlign: props.lang_align }}>{Tabstext.recordingGuide1[props.lang]}</div>
            placement="bottom"
        >
            <span>
                <OutlinedButton
                    startIcon={<PlayIcon />}
                    variant="outlined"
                    onClick={() => handleMenuClick(audioRefDisplay)}
                    disabled={isPlayDisabled}
                    >
                    {label}
                </OutlinedButton>
            </span>
        </Tooltip>
    )
}

function PlayFdbkGroup(props) {
    const label =  props.audioState==1 ? Tabstext.PauseTab[props.lang] : Tabstext.PlayTab[props.lang]
    const isPlayDisabled = false; // props.audioState==0 ? false : true

    const handleMenuClick = (val) => {
        props.PlayFlowCallback('play',val,props.tempo,props.recordingDelay);
    };

    return (
        <div >
            <OutlinedButton
                startIcon={<PlayIcon />}
                variant="outlined"
                onClick={() => handleMenuClick('recorded-audio')}
                disabled={isPlayDisabled}
                >
                {label}
            </OutlinedButton>
        </div>

    )
}

function RecordGroup(props) {
    const audioGuideDisplay = props.audioGuideDisplay? Object.entries(props.audioGuideDisplay)[0][0] : null;
    const label =  props.recState==1 ? Tabstext.StopTab[props.lang] : Tabstext.Record2Tab[props.lang];
    const thisclass =  props.recState==1 ? 'fa fa-stop' : 'fa fa-circle';
    const isRecDisabled = (props.audioState==1 || props.audioState==2) ? true : false;

    const handleButtonClick = (val) => {
        if (props.recState==1) // do stop
            props.RecFlowCallback();
        else
            props.RecFlowCallback(val);
    };
    return (
        <Tooltip
            title=<div style={{ textAlign: props.lang_align }}>{Tabstext.recordingGuide2[props.lang]}</div>
            placement="bottom"
        >
            <span>
                <OutlinedButton
                    startIcon={<RecordIcon />}
                    variant="outlined"
                    onClick={() => handleButtonClick(audioGuideDisplay)}
                    disabled = {isRecDisabled}
                    style={{ marginLeft: '0px' }}
                    >
                    <i className={thisclass}></i>{label}
                </OutlinedButton>
            </span>
        </Tooltip>
    )
}

export default RectestAudio;

