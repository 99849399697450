import React, { useState, useEffect} from "react";
import { Popover, Slider, Button, Tooltip } from '@mui/material';
import Tabstext from './tabstext';
import { TextButton, SmallTitle,} from './customized_components'
import { axiosInstance, } from './axios_instance';
import SettingsIcon from '@mui/icons-material/Settings';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

function ToleranceSetting(props) {
    if (!props.pitch_tolerance_range)
        return null;

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSave = () => {
        handleClose();
        if (props.groupDisplay || props.feedbackDisplay)
            props.setPitchToleranceCallback(sliderValue);
        else {
            axiosInstance.post('/api/user_settings/', { pitch_tolerance: sliderValue }).then();
            props.setUserCallback({ 'pitch_tolerance': sliderValue })
        }
    };

    const [sliderValue, setSliderValue] = useState(props.pitchTolerance); // Starting value
    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };
    useEffect(() => {
        if (props.feedbackData) {
            setSliderValue(props.pitchTolerance);
        }
    }, [props.feedbackData?.run_pk]);

    // the marks reflects full-tone fractions while pitch tolerance is defined in semitones
    const marks = props.pitch_tolerance_range;
    const minval = marks[0].value;
    const maxval = marks[marks.length-1].value;
    const step =  marks[1].value - minval;

    //useEffect(() => {
    //    console.log('Component mounted');
    //}, []);

    return (
        <>
            <Tooltip title={props.tooltipText} placement="top" >
                <TextButton size='small' onClick={handleClick} >
                    <SmallTitle >{Tabstext.SetTolerance[props.lang]}</SmallTitle>
                    {!props.feedbackDisplay && <SettingsIcon size='small' />}
                    {props.feedbackDisplay && <MusicNoteIcon style={{color:'green', height:'20px'}} />}
                </TextButton>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableEnforceFocus
                >
                <div style={{
                    padding:'0 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    }}>
                    <Slider
                      size="small"
                      value={sliderValue}
                      min={minval} // Minimum value
                      max={maxval} // Maximum value
                      step={0.5} // only discrete marks
                      marks={marks} // Show marks at each step
                      onChange={handleSliderChange}
                      style={{ width: '100px' }}
                    />
                    <Button onClick={handleSave}
                        style={{ mt: 2, alignSelf: 'flex-start', textTransform: 'none', }} >
                        {props.feedbackDisplay? Tabstext.ReanalyzeTab[props.lang]:Tabstext.SaveTab[props.lang]}
                    </Button>
                </div>
            </Popover>
        </>
    );
}

export default ToleranceSetting
